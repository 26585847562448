import React from 'react';
import { ToastType } from './toast.service';

const Toast = ({ message, toastType }) => {
  return (
    <div className="toast-container">
        <div className={`toast-container-box 
            ${toastType === ToastType.Success ? "success" : ""}
            ${toastType === ToastType.Error ? "error" : ""}`}>
            <span>{message}</span>
        </div>
    </div>
  );
};

export default Toast;