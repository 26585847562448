import axios from 'axios';

// export const fetchCareTypes = () => {
//     return axios.get(`/api/services`, {
//       headers: {
//           'Content-Type': 'application/json',
//           'Accept': 'application/json'
//       }
//   })
// }

export const fetchVetsFromFilter = (filter: any) => {
  debugger;

  const varyNumber = (number: number) => {
    // Calculate the range within 0.05% of the number
    const range = number * 0.0005;
  
    // Generate a random variation within the range
    const variation = Math.random() * (2 * range) - range;
  
    // Apply the variation to the number
    const variedNumber = number + variation;
  
    return variedNumber;
  }
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const mockData = [
        {
          id: 1,
          name: "Animal Care Clinic",
          address: "123 Main Street, Anytown, NY 12345",
          rating: 4.5,
          reviewCount: 104,
          careTypes: ["Urgent Care", "Vet Pharmacy", "Telemedicine", "Veterinarian"],
          lat: varyNumber(filter.lat),
          lng: varyNumber(filter.lng),
          resultNum: 1
        },
        {
          id: 2,
          name: "Paws & Claws Veterinary Hospital",
          address: "456 Elm Avenue, Somewhereville, CA 67890",
          rating: 4.5,
          reviewCount: 104,
          careTypes: ["Urgent Care", "Vet Pharmacy", "Telemedicine", "Veterinarian"],
          lat: varyNumber(filter.lat),
          lng: varyNumber(filter.lng),
          resultNum: 2
        },
        {
          id: 3,
          name: "Happy Tails Pet Clinic",
          address: "789 Oak Lane, Nowhere City, TX 23456",
          rating: 4.5,
          reviewCount: 104,
          careTypes: ["Urgent Care", "Vet Pharmacy", "Telemedicine", "Veterinarian"],
          lat: varyNumber(filter.lat),
          lng: varyNumber(filter.lng),
          resultNum: 3,
        },
        {
          id: 4,
          name: "Healthy Pets Veterinary Clinic",
          address: "321 Maple Street, Imaginary Town, FL 34567",
          rating: 4.5,
          reviewCount: 104,
          careTypes: ["Urgent Care", "Vet Pharmacy", "Telemedicine", "Veterinarian"],
          lat: varyNumber(filter.lat),
          lng: varyNumber(filter.lng),
          resultNum: 4
        },
        {
          id: 5,
          name: "Pet Care Specialists",
          address: "654 Pine Road, Fictionville, IL 45678",
          rating: 4.5,
          reviewCount: 104,
          careTypes: ["Urgent Care", "Vet Pharmacy", "Telemedicine", "Veterinarian"],
          lat: varyNumber(filter.lat),
          lng: varyNumber(filter.lng),
          resultNum: 5
        },
        {
          id: 6,
          name: "Pet Wellness Center",
          address: "987 Cedar Court, Dreamland, AZ 56789",
          rating: 4.5,
          reviewCount: 104,
          careTypes: ["Urgent Care", "Vet Pharmacy", "Telemedicine", "Veterinarian"],
          lat: varyNumber(filter.lat),
          lng: varyNumber(filter.lng),
          resultNum: 6
        },
        {
          id: 7,
          name: "Companion Animal Hospital",
          address: "135 Spruce Drive, Fantasy City, OH 67890",
          rating: 4.5,
          reviewCount: 104,
          careTypes: ["Urgent Care", "Vet Pharmacy", "Telemedicine", "Veterinarian"],
          lat: varyNumber(filter.lat),
          lng: varyNumber(filter.lng),
          resultNum: 7
        },
        {
          id: 8,
          name: "Loving Paws Veterinary Clinic",
          address: "246 Birch Avenue, Wonderland, WA 78901",
          rating: 4.5,
          reviewCount: 104,
          careTypes: ["Urgent Care", "Vet Pharmacy", "Telemedicine", "Veterinarian"],
          lat: varyNumber(filter.lat),
          lng: varyNumber(filter.lng),
          resultNum: 8
        },
        {
          id: 9,
          name: "Family Pet Medical Center",
          address: "579 Willow Lane, Enchanted Village, NJ 89012",
          rating: 4.5,
          reviewCount: 104,
          careTypes: ["Urgent Care", "Vet Pharmacy", "Telemedicine", "Veterinarian"],
          lat: varyNumber(filter.lat),
          lng: varyNumber(filter.lng),
          resultNum: 9
        },
        {
          id: 10,
          name: "Family Pet Medical Center",
          address: "864 Cherry Street, Makebelieve Town, PA 90123",
          rating: 4.5,
          reviewCount: 104,
          careTypes: ["Urgent Care", "Vet Pharmacy", "Telemedicine", "Veterinarian"],
          lat: varyNumber(filter.lat),
          lng: varyNumber(filter.lng),
          resultNum: 10
        },
      ]
      resolve(mockData);
    }, 10)
  })
}