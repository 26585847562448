import React from 'react';
import * as MapUtil from '../../../util/map_util'

class VetSearchCard extends React.Component {

    constructor(props: any){
        super(props);
        this.handleCareTypes = this.handleCareTypes.bind(this);
    }

    handleCareTypes(){
        return (<div className="vet-search-container-left-vets-card-right-care-child">
                {this.props.business.services.map(service => service.name).slice(0,2).join(", ")}
            </div>);
    }

    render() {
        return (<div 
            className={`vet-search-container-left-vets-card ${this.props.isClickedMarker ? 'bulge' : ''}`}
            id={`vet-${this.props.business.id}`}
            onMouseEnter={this.props.handleMouseEnter}
            onMouseLeave={this.props.handleMouseLeave}
            onClick={this.props.handleClick}
        >
            <div className='vet-search-container-left-vets-card-left'>
                <div 
                    className='vet-search-container-left-vets-card-left-result-num'
                >
                    {this.props.business.number}
                </div>
                <div className='vet-search-container-left-vets-card-left-photo'>
                    <img src={this.props.business.image_url} alt="" />
                </div>
            </div>
            <div className='vet-search-container-left-vets-card-right'>
                <div className='vet-search-container-left-vets-card-right-name'>
                    <div>
                        {this.props.business.name}
                        <span>
                            <i className="fa-solid fa-circle-check"></i>
                            <i className="fa-solid fa-heart-circle-exclamation"></i>
                        </span>
                    </div>
                    <div className='vet-search-container-left-vets-card-right-name-right'>
                        <i className="fa-solid fa-square-arrow-up-right"
                            onClick={() => this.props.goToProfilePage(this.props.business.id)}
                            title="View Business Page"
                        ></i>
                    </div>
                </div>
                <div className='vet-search-container-left-vets-card-right-address'>
                    <div className='vet-search-container-left-vets-card-right-address-miles'><i className="fa-solid fa-location-dot"></i>{this.props.business.distance} Miles</div>
                    {this.props.business.address}
                </div>
                <div className='vet-search-container-left-vets-card-right-reviews'>
                    <i className="fa-solid fa-star"></i>{this.props.business.average_reviews.averages.recommend}  ({this.props.business.review_count} Reviews)
                </div>
                <div className='vet-search-container-left-vets-card-right-care'>
                    <i className="fa-solid fa-notes-medical"></i>
                    {this.handleCareTypes()}
                    {this.props.business.services.length > 2 ? 
                        <div className="vet-card-info-care-types-child additional">
                            +{this.props.business.services.length - 2}
                        </div> 
                        : ""}
                </div>
                <div className='vet-search-container-left-vets-card-right-appointment'>
                    Appointment Available - Click to Book!
                </div>
            </div>
        </div>)
    }
}

export default VetSearchCard;