export const CLOSE_MODAL = "CLOSE_MODAL";
export const OPEN_REVIEW_MODAL = "OPEN_REVIEW_MODAL";
export const OPEN_BUSINESS_MODAL = "OPEN_BUSINESS_MODAL";
export const ADMIN_OPEN_ANIMALS_FORM = "ADMIN_OPEN_ANIMALS_FORM";
export const ADMIN_OPEN_BUSINESS_TYPES_FORM = "ADMIN_OPEN_BUSINESS_TYPES_FORM";
export const ADMIN_OPEN_SERVICES_FORM = "ADMIN_OPEN_SERVICES_FORM";
export const SHOW_PHOTO_FORM = "SHOW_PHOTO_FORM";
export const SHOW_CONFIRMATION_FORM = "SHOW_CONFIRMATION_FORM";

export const showAdminAnimalsForm = (animal: any) => ({
    type: ADMIN_OPEN_ANIMALS_FORM,
    animal
})

export const showAdminBusinessTypesForm = (businessType: any) => ({
    type: ADMIN_OPEN_BUSINESS_TYPES_FORM,
    businessType
})

export const showConfirmationForm = (message, onConfirm) => ({
    type: SHOW_CONFIRMATION_FORM,
    message,
    onConfirm
})

export const showAdminServicesForm = (service: any) => ({
    type: ADMIN_OPEN_SERVICES_FORM,
    service
})

export const showPhotoForm = (user: any) => ({
    type: SHOW_PHOTO_FORM,
    user
})

export const showReviewModal = (review: any) => ({
    type: OPEN_REVIEW_MODAL,
    review
})


export const showBusinessModal = (business: any) => ({
    type: OPEN_BUSINESS_MODAL,
    business
})

export const closeModal = () => ({
    type: CLOSE_MODAL
})