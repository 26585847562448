import React from "react"

const UserProfilePets = (props: any) => {

    const petCard = (pet: any) => (<li 
        className="user-profile-container-right-pets-card"
        id={pet.id}
    >
        <img src={pet.image_url} alt="" />
        <div className="right">
            <header>
                {pet.name}
            </header>
            <div className="info">
                <div className="bold">Age: </div>
                {pet.age}
            </div>
            <div className="info">
                <div className="bold">Animal: </div>
                {pet.animal}
            </div>
            <div className="info">
                <div className="bold">Breed: </div>
                {pet.breed}
            </div>
        </div>
    </li>)

    return <div className="user-profile-container-right-pets">
        <header className="header">
            <div>
                {props.firstName}'s Pets
            </div>
            <div className="add-pet">
                <i className="fa-solid fa-plus"></i> Add Pet
            </div>
        </header>
        <ul>
            {props.pets.map((pet: any) => petCard(pet))}
        </ul>
    </div>
};

export default UserProfilePets;