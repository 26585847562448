import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ServiceFormWithToast from "./service-form";
import { updateService, createService, deleteService } from "../../actions/service-actions";

const mapStateToProps = (state: any, ownProps: any) => {
    return {

    };
}

const mapDispatchToProps = (dispatch: any) => ({
    updateService: (service: any, showToast: Function) => dispatch(updateService(service ,showToast)),
    createService: (service: any, showToast: Function) => dispatch(createService(service, showToast)),
    deleteService: (serviceId: number, showToast: Function) => dispatch(deleteService(serviceId, showToast))
    // TODO: Add in Create Animal Types action
});

const ServiceFormContainer = withRouter(connect(mapStateToProps,mapDispatchToProps)(ServiceFormWithToast));
export default ServiceFormContainer;