import * as ServiceApiUtil from '../util/service-api-util';
import { ToastType } from "../shared/toast.service";
import { closeModal } from './ui-actions';

export const RECEIVE_SERVICE = 'RECEIVE_SERVICE';
export const RECEIVE_SERVICES = 'RECEIVE_SERVICES';
export const REMOVE_SERVICE = "REMOVE_SERVICE";

export const receiveServices = (services: any) => ({
    type: RECEIVE_SERVICES,
    services
})

export const receiveService = (service: any) => ({
    type: RECEIVE_SERVICE,
    service
})

export const removeService = (serviceId: number) => ({
    type: REMOVE_SERVICE,
    serviceId
})

export const fetchServices = () => (dispatch: any) => {
    ServiceApiUtil.fetchServices().then(services => {
        dispatch(receiveServices(services.data))
    })
}

export const updateService = (service: any, showToast: Function) => (dispatch: any) => {
    ServiceApiUtil.updateService(service).then( service => {
        dispatch(receiveService(service.data));
        dispatch(closeModal());
        showToast(ToastType.Success, "Service successfully updated.");
    }, err => {
        showToast(ToastType.Error,"Service not updated.");
    })
}

export const createService = (service: any, showToast: Function) => (dispatch: any) => {
    ServiceApiUtil.createService(service).then( (service: any) => {
        dispatch(receiveService(service.data));
        dispatch(closeModal());
        showToast(ToastType.Success, "Service successfully created.");
    }, err => {
        showToast(ToastType.Error,"Service not created.");
    })
}

export const deleteService = (serviceId: number, showToast: Function) => (dispatch: any) => {
    ServiceApiUtil.deleteService(serviceId).then( res => {
        dispatch(removeService(serviceId));
        dispatch(closeModal());
        showToast(ToastType.Success, "Service successfully deleted.");
    })
}
