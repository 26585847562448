import React from "react";
import { Provider } from "react-redux";
import App from "./app";
import { HashRouter, Route } from "react-router-dom";
import bcrypt from 'bcryptjs';

const Root = ({store}) => (
    <Provider store={store}>
        <HashRouter>
            <Route path="/" component={CoverPage} />
            <App/>
        </HashRouter>
    </Provider>
)

class CoverPage extends React.Component {

    hashedPassword = "";

    constructor(props){
        super(props);
        this.state={
            password: "",
            visible: true
        }

        this.bcrypt = require('bcryptjs');
        this.hashedPassword = "$2a$10$ZQvnoPBelni9rR/b5g84vuHRV3ylyrTT2pPEGccvq0t.N8GGNCY8S";
        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInput(type: any){
        return (e: any) => {
            this.setState({ [type]: e.currentTarget.value})
        }
    }

    handleSubmit(e: any){
        e.preventDefault();
        if (bcrypt.compareSync(this.state.password, this.hashedPassword)){
            this.setState({visible: false});
        } else {
            this.setState({visible: true});
        }
    }

    render() {

        if (this.state.visible){
            return (<div className="cover-page-container">
                <form
                    onSubmit={this.handleSubmit}
                >
                    <label>Input Password:</label>
                    <input type="password" 
                        value={this.state.password}
                        onChange={this.handleInput("password")}
                    />
                </form>
            </div>)
        } else {
            return (<div></div>);
        }
    }
}

export default Root;