import React from 'react';

class AdminUsers extends React.Component {

    constructor(props: any){
        super(props);

        this.props.clearAllUsers(this.props.currentUser.id);

        this.state = {
            page: 0,
            isLoading: true,
            loadedAll: false
        }

        this.toggleLoading = this.toggleLoading.bind(this);
        this.incrementPage = this.incrementPage.bind(this);
        this.toggleLoadedAll = this.toggleLoadedAll.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.userCard = this.userCard.bind(this);
    }

    componentDidMount(): void {

        const pagingFunctions = {
            incrementPage: this.incrementPage,
            toggleLoading: this.toggleLoading,
            toggleLoadedAll: this.toggleLoadedAll
        } 
        this.props.fetchUsers(this.state.page, pagingFunctions);

        const container = document.querySelector('.body'); 
        container.addEventListener('scroll', this.handleScroll);
    }

    toggleLoading(){
        this.setState({isLoading: false});
    }

    toggleLoadedAll(){
        this.setState({loadedAll: true});
    }

    incrementPage(){
        this.setState({page: this.state.page + 1})
    }

    handleScroll() {
        debugger;
        const container = document.querySelector('.body'); 
        const { scrollTop, clientHeight, scrollHeight } = container;

        if (scrollTop + clientHeight + 5 >= scrollHeight && !this.state.loadedAll && !this.state.isLoading) {
            this.setState({isLoading: true});
            const pagingFunctions = {
                incrementPage: this.incrementPage,
                toggleLoading: this.toggleLoading,
                toggleLoadedAll: this.toggleLoadedAll
            } 
            this.props.fetchUsers(this.state.page + 1, pagingFunctions);
        }
    }

    formatDate(dateString: string): string {
        const date = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    userCard(user: any, index: number) {
        return <li className={`user-card-container`}
            key={index}
        >
            <div className={`user-card ${user.id === this.props.currentUser?.id && "admin-background-light"}`}>
                <div className='user-card-left'>
                    {user.photoUrl ? <img src={user.photoUrl} alt="" /> : 
                        <i className='fa-solid fa-user'></i>
                    }
                </div>
                <div className='user-card-right'>
                    <div className='user-card-right-top'>
                        <header>{index + 1}. {user.first_name} {user.last_name}</header>
                        <i className="fa-solid fa-square-arrow-up-right"
                            onClick={() => this.props.history.push(`/user-profile/${user.username}`)}
                            title="View User Page"
                        ></i>
                    </div>
                    <div className='user-card-right-info'>
                        <i className="fa-regular fa-user"></i>
                        <label htmlFor="">{user.username}</label>
                    </div>
                    <div className='user-card-right-info'>
                        <i className="fa-regular fa-envelope"></i>
                        <label htmlFor="">{user.email}</label>
                    </div>
                    <div className='user-card-right-info'>
                        <i className="fa-regular fa-calendar"></i>
                        <label htmlFor="">Joined {this.formatDate(user.created_at)}</label>
                    </div>
                    <div className='user-card-right-info'>
                        <label className={user.activated ? "activated" : "inactivated"}>
                            <i className={`fa-regular fa-circle-check ${user.activated ? "activated" : "inactivated"}`}></i>
                            {user.activated ? "Activated" : "Not Activated"}
                        </label>
                    </div>
                </div>
            </div>
        </li>
    }

    render() {
        return <div className='admin-users-container'>
            <div className="top-button">
                <button
                    onClick={() => this.props.history.push('/admin-overview')}
                    className="admin-businesses-container-head-buttons back"
                >
                    <i className="fa-solid fa-arrow-left"></i> Back to Admin Overview
                </button>
            </div>
            <ul>
                {Object.values(this.props.users).map((user:any, index) => this.userCard(user, index))}
            </ul>
            {this.state.isLoading && 
                <div
                    className="admin-users-container-loader"
                >
                    <i className="fa-solid fa-rotate loading-spinner"></i>
                    <div>Fetching Users...</div>
                </div>
            }
            {this.state.loadedAll && 
                <div className="admin-users-container-loader">Loaded all results</div>
            }
        </div>
    }
}

export default AdminUsers;