import { RECEIVE_CURRENT_USER } from "../actions/session_actions";
import { RECEIVE_USER, RECEIVE_USERS, CLEAR_ALL_USERS } from "../actions/user_actions";

export default (state = {}, action: any)=>{
    Object.freeze(state);
    let nextState = Object.assign({}, state)

    switch(action.type){
        case RECEIVE_CURRENT_USER:
            return Object.assign(nextState, {[action.currentUser.id]: action.currentUser});
        case RECEIVE_USER:
            return Object.assign(nextState, {[action.user.id]: action.user});
        case RECEIVE_USERS:
            Object.values(action.users).forEach((user: any) => {
                nextState[user.id] = user;
            });
            return nextState;
        case CLEAR_ALL_USERS:
            return Object.assign({}, {[action.currentUserId]: nextState[action.currentUserId]});
        default:
            return state;
    }
}