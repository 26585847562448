import * as VetApiUtil from '../util/vet-api-util';

export const RECEIVE_CLINIC = "RECEIVE_CLINIC";
export const RECEIVE_CLINICS = "RECEIVE_CLINICS";

export const receiveClinic = (clinic : any) => ({
    type: RECEIVE_CLINIC,
    clinic
});

export const receiveClinics = (clinics : any) => ({
    type: RECEIVE_CLINICS,
    clinics
});

export const fetchVetsFromFilter = (filter: any) => (dispatch: any) => {
    VetApiUtil.fetchVetsFromFilter(filter).then(vets => {
        debugger;
        dispatch(receiveClinics(vets));
    })
}