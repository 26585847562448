import axios from 'axios';

export const fetchBusinessTypes = () => {
    return axios.get(`/api/business_types`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
}

export const updateBusinessTypes = (business_type: any) => {
    return axios.patch(`/api/business_types/${business_type.business_type.id}`, business_type, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
}