import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, withRouter } from 'react-router-dom';

const mapStateToProps = (state: any, ownProps: any) => {
    return { 
        loggedIn: Boolean(state.session.id),
        isAdmin: state.entities.users[state.session.id]?.is_admin
    };
};


const Auth = ({ component: Component, path, loggedIn, exact }) => (
    <Route
      path={path}
      exact={exact}
      render={props =>
        !loggedIn ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );

const Protected = ({ component: Component, path, loggedIn, exact }) => {
    return <Route path={path} exact={exact} render={(props) => (
        loggedIn ? (
        <Component {...props} />
        ) : (
        <Redirect to="/user-login" />
        )
    )} />
};

const Admin = ({ component: Component, path, loggedIn, exact, isAdmin }) => {
    return <Route 
        path={path} 
        exact = {exact} 
        render = {props => 
            (loggedIn && isAdmin ? 
                (<Component {...props}/>) :
                (<Redirect to="/"/>))
        }
    />
}

export const AuthRoute = withRouter(connect(mapStateToProps)(Auth));
export const ProtectedRoute = withRouter(connect(mapStateToProps)(Protected));
export const AdminRoute = withRouter(connect(mapStateToProps)(Admin));