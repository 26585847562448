import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AdminOverview from "./admin-overview";
import { fetchAnimalTypes } from "../../../actions/animal-actions";
import { fetchServices } from "../../../actions/service-actions";
import { 
    closeModal, 
    showAdminAnimalsForm,
    showAdminServicesForm
} from "../../../actions/ui-actions";
import { fetchBusinessTypes } from "../../../actions/business-type-actions";
import { showAdminBusinessTypesForm } from "../../../actions/ui-actions";
import { clearAllBusinesses } from "../../../actions/business-actions";
import { clear } from "console";

const mapStateToProps = (state: any, ownProps: any) => ({
    animals: state.entities.animals,
    businessTypes: state.entities.businessRoot.businessTypes,
    services: state.entities.services
})

const mapDispatchToProps = (dispatch: any) => ({
    fetchAnimalTypes: () => dispatch(fetchAnimalTypes()),
    fetchBusinessTypes: () => dispatch(fetchBusinessTypes()),
    fetchServices: () => dispatch(fetchServices()),
    showAdminAnimalsForm: (animal: any) => dispatch(showAdminAnimalsForm(animal)),
    showAdminBusinessTypesForm: (businessType: any) => dispatch(showAdminBusinessTypesForm(businessType)),
    showAdminServicesForm: (service: any) => dispatch(showAdminServicesForm(service)),
    clearAllBusinesses: () => dispatch(clearAllBusinesses()),
    closeModal: () => dispatch(closeModal())
});

const AdminOverviewContainer = withRouter(connect(mapStateToProps,mapDispatchToProps)(AdminOverview));
export default AdminOverviewContainer;
