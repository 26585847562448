import React from "react";

class PetTalksComponent extends React.Component {

    render(){
        return (<div className="pet-talks-container">
            <div className="pet-talks-container-module">
                PET TALKS AHAHAH
            </div>
            <div className="pet-talks-container-module">
                PET TALKS AHAHAH
            </div>
            <div className="pet-talks-container-module">
                PET TALKS AHAHAH
            </div>
        </div>)
    }
}

export default PetTalksComponent;