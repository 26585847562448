import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AdminUsers from "./admin-users";
import { fetchUsers } from "../../../../actions/user_actions";
import { clearAllUsers } from "../../../../actions/user_actions";

const mapStateToProps = (state: any, ownProps: any) => ({
    users: state.entities.users,
    currentUser: state.entities.users[state.session.id],
})

const mapDispatchToProps = (dispatch: any) => ({
    fetchUsers: (pageNumber: number, pagingFunctions: object) => dispatch(fetchUsers(pageNumber, pagingFunctions)),
    clearAllUsers: (currentUserId: number) => dispatch(clearAllUsers(currentUserId))
});

const AdminUsersContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminUsers));
export default AdminUsersContainer;