import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AboutUs from "./about_us";


const mapStateToProps = (state: any, ownProps:any) => {
    return {

    };
}

const mapDispatchToProps = (dispatch: any) => ({

});

const AboutUsContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(AboutUs));
export default AboutUsContainer;