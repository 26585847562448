import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PetTalksComponent from "./pet-talks";

const mapStateToProps = (state: any, ownProps: any) => {
    return {

    }   
}

const mapDispatchToProps = (dispatch: any) => ({

});

const PetTalksContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(PetTalksComponent));
export default PetTalksContainer;