import React from "react";
import NavBarContainer from "./navbar/navbar_container";
import { BrowserRouter, Switch, Route, Link, Redirect} from "react-router-dom";
import { AuthRoute, ProtectedRoute, AdminRoute } from '../util/route_util';
import AboutUsContainer from "./about_us/about_us_container";
import LaunchPageContainer from "./launch_page/launch_page_container";
import PetTalksContainer from "./pet-talks/pet-talks-container";
import ClaimYourClinicContainer from "./claim-your-clinic/claim-your-clinic-container";
import UserProfileContainer from "./user/user-profile/user-profile-container";
import VetSearchContainer from "./vet/vet-search/vet-search-container";
import AdminOverviewContainer from "./admin/admin-overview/admin-overview-container";
import ModalContainer from "./modal/modal";
import VetProfileContainer from "./vet/vet-profile/vet-profile-container";
import AdminBusinessesContainer from "./admin/admin-overview/admin-businesses/admin-businessess-container";
import AdminUsersContainer from "./admin/admin-overview/admin-users/admin-users-container";

const MainApp = () => (
    <div id="app-logged-in">
        <ModalContainer/>
        <Switch>
            <Route exact path="/" component={LaunchPageContainer} />
            <Route path="/vetsearch" component={NavBarAdded(VetSearchContainer)}/>
            <Route path="/vet-profile/:vetId" component={NavBarAdded(VetProfileContainer)}/>
            <Route exact path="/about-us" component={NavBarAdded(AboutUsContainer)}/>
            <Route exact path="/pet-talks" component={NavBarAdded(PetTalksContainer, "pet-talks")}/>
            <Route exact path="/claim-your-clinic" component={NavBarAdded(ClaimYourClinicContainer)}/>
            <ProtectedRoute path="/user-profile/:username" component={NavBarAdded(UserProfileContainer)} />
            <AdminRoute path="/admin-overview/businesses" component={NavBarAdded(AdminBusinessesContainer)}/>
            <AdminRoute path="/admin-overview/users" component={NavBarAdded(AdminUsersContainer)}/>
            <AdminRoute path="/admin-overview" component={NavBarAdded(AdminOverviewContainer)}/>
            <Redirect to="/" />
        </Switch>
    </div>
)

/*
    NavBarAdded
        This was created to accomodating the scroll snapping style.
*/
const NavBarAdded = (Component: any, className: string = "") => {
    const WrappedComponent = ()  => (
        <div className={`body ${className}`}>
            <NavBarContainer/>
            <Component/>
        </div>);
    return WrappedComponent
}

const PetTalks = () => (
    <div className="pet-talks-container">
        Pet Talks
    </div>
)

export default MainApp;