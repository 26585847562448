import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ClaimYourClinic from "./claim-your-clinic";

const mapStateToProps = (state: any, ownProps:any) => {
    return {

    };
}

const mapDispatchToProps = (dispatch: any) => ({

});

const ClaimYourClinicContainer = withRouter(connect(mapStateToProps,mapDispatchToProps)(ClaimYourClinic));
export default ClaimYourClinicContainer;
