import * as BusinessApiUtil from '../util/business-api-util';
import { ToastType } from '../shared/toast.service';
import { closeModal } from './ui-actions';

export const RECEIVE_BUSINESS: string = "RECEIVE_BUSINESS";
export const RECEIVE_BUSINESSES: string = 'RECEIVE_BUSINESSES';
export const CLEAR_ALL_BUSINESSES: string = "CLEAR_ALL_BUSINESSES";
export const RECEIVE_SEARCHED_BUSINESSES: string = "RECEIVE_SEARCHED_BUSINESSES";

export const receiveBusiness = (business: any) => ({
    type: RECEIVE_BUSINESS,
    business
})

export const receiveBusinesses = (businesses: any) => ({
    type: RECEIVE_BUSINESSES,
    businesses
})

export const clearAllBusinesses = () => ({
    type: CLEAR_ALL_BUSINESSES
})

export const receiveSearchedBusinesses = (searchedBusinesses: any) => ({
    type: RECEIVE_SEARCHED_BUSINESSES,
    searchedBusinesses
})

export const createBusiness = (business: any, showToast: Function) => (dispatch: any) => {
    BusinessApiUtil.createBusiness(business).then((business: any) => {
        dispatch(receiveBusiness(business.data));
        dispatch(closeModal());
        showToast(ToastType.Success, "Business sucessfully created.");
    }, err => {
        showToast(ToastType.Error, "Business not created.");
    })
}

export const updateBusiness = (business: any, showToast: Function) => (dispatch: any) => {
    BusinessApiUtil.updateBusiness(business).then((business: any) => {
        dispatch(receiveBusiness(business.data));
        dispatch(closeModal());
        showToast(ToastType.Success, "Business sucessfully updated.");
    }, err => {
        showToast(ToastType.Error, "Business not updated.");
    })
}

export const fetchBusinesses = (page: number, pagingFunctions: object) => (dispatch: any) => {
    BusinessApiUtil.fetchBusinesses(page).then((businesses: any) => {
        setTimeout(() => {
            pagingFunctions.toggleLoading();
            if (businesses.data.length > 0){
                dispatch(receiveBusinesses(businesses.data));
                pagingFunctions.incrementPage();
            } else {
                pagingFunctions.toggleLoadedAll();
            }
        }, 10)
    })
}

export const fetchBusinessesFromFilter = (filter: any) => (dispatch: any) => {
    BusinessApiUtil.fetchBusinessesFromFilter(filter).then((businesses: any) => {
        dispatch(receiveSearchedBusinesses(businesses.data));
    })
}

export const fetchBusiness = (id: number, businessProfileFunctions: any) => (dispatch: any) => {
    BusinessApiUtil.fetchBusiness(id).then((business:any) => {
        dispatch(receiveBusiness(business.data));
    }, err => {
        businessProfileFunctions.setNotFound();
    })
}