import axios from 'axios';

export const fetchServices = () => {
    return axios.get(`/api/services`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
}

export const updateService = (service: any) => {
    return axios.patch(`/api/services/${service.service.id}`, service, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
}

export const createService = (service: any) => {
    return axios.post(`/api/services`, service, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
}

export const deleteService = (serviceId: number) => {
    return axios.delete(`/api/services/${serviceId}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
}