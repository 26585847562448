import React, { createContext } from 'react';
import Toast from './toast';

const ToastContext = createContext();

class ToastService extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        toastType: ToastType,
        toast: null
    };
  }

  showToast = (toastType, message) => {
    this.setState({ toast: message, toastType: toastType });
    setTimeout(() => {
      this.setState({ toast: null });
    }, 3000); // Hide the toast after 3 seconds
  };

  render() {
    const { children } = this.props;
    const { toast, toastType } = this.state;

    return (
      <ToastContext.Provider value={this.showToast}>
        {children}
        {toast && <Toast message={toast} toastType={toastType} />}
      </ToastContext.Provider>
    );
  }
}

export default ToastService;
export const ToastConsumer = ToastContext.Consumer;
export const ToastProvider = ToastContext.Provider;

export enum ToastType {
    Success = 1,
    Error = 2,
    Warning = 3
}