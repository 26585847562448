import React from "react";

class Footer extends React.Component {

    render(){
        return (<div className="footer-container">
            <div className="footer-container-left">
                <img src={window.logoBanner} alt="Logo" id="footer-logo"/>
                <div className="footer-container-left-social-media-icons">
                    <i className="fa-brands fa-instagram"></i>
                    <i className="fa-brands fa-facebook-f"></i>
                    <i className="fa-brands fa-linkedin"></i>
                </div>
                <label htmlFor="">Follow Us!</label>
                <div className="copyright">Ⓒ 2023 April’s Journey, Inc. All rights reserved.</div>
            </div>
            <div className="divider-line"></div>
            <div className="footer-container-right">
                <div className="footer-container-right-left">
                    <a href="">About Us</a>
                    <a href="">Support</a>
                    <a href="">Contact Us</a>
                    <a href="">Press</a>
                    <a href="">Claim Your Clinic</a>
                </div>
                <div className="footer-container-right-right">
                    <a href="">Legal</a>
                    <a href="">Terms of Use</a>
                    <a href="">Privacy</a>
                </div>
            </div>
        </div>)
    }

}

export default Footer;