import React from 'react';
import { BrowserRouter, Switch, Route, Link} from "react-router-dom";
// import NavBarContainer from './navbar/navbar_container';
import { AuthRoute, ProtectedRoute, AdminRoute } from '../util/route_util';
import LoginFormContainer from './session_forms/login_form_container';
import SignUpFormContainer from './session_forms/signup_form_container';
import UserProfileContainer from './user/user-profile/user-profile-container';
import { Redirect } from 'react-router-dom';
import MainApp from './main_app';
import ToastService from '../shared/toast.service';

const App = () => (
    <ToastService>
        <div className="app">
            <Switch>
                <AuthRoute exact path="/user-signup" component={SignUpFormContainer}/>
                <AuthRoute exact path="/user-login" component={LoginFormContainer}/>
                <AdminRoute exact path="/admin" component={Admin}/>
                <Route path="/" component={MainApp}/>
            </Switch>
        </div>
    </ToastService>
)


const Admin = () => (<div>
    ADMIN
</div>)


export default App;