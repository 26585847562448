import { RECEIVE_SERVICE, RECEIVE_SERVICES, REMOVE_SERVICE } from "../actions/service-actions";

export default (state = {}, action: any)=>{
    Object.freeze(state);
    let nextState: any = Object.assign({}, state)

    switch(action.type){
        case RECEIVE_SERVICE:
            nextState[action.service.id] = action.service;
            return nextState;

        case RECEIVE_SERVICES:
            action.services.forEach((service: any) => {
                nextState[service.id] = service;
            })
            return nextState;

        case REMOVE_SERVICE:
            delete nextState[action.serviceId];
            return nextState;

        default:
            return state;
    }
}
