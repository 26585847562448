import { connect } from "react-redux";
import { clearSessionErrors, loginUser } from "../../actions/session_actions";
import SessionForm from "./session_form";
import { signinUserWithGoogle } from "../../actions/session_actions";

const mapStateToProps = (state: any, props: any) => ({
    errors: state.errors.session,
    checkVerificationEmail: state.session.checkVerificationEmail,
    formType: "Log In"
});

const mapDispatchToProps = (dispatch: any) => ({
    processSessionForm: (user: any) => dispatch(loginUser(user)),
    clearErrors: () => dispatch(clearSessionErrors()),
    signinUserWithGoogle: (jwt: any) => dispatch(signinUserWithGoogle(jwt))
});

const LoginFormContainer = connect(mapStateToProps, mapDispatchToProps)(SessionForm);
export default LoginFormContainer;