import * as SessionApiUtil from '../util/session_api_util';
import { ToastType } from "../shared/toast.service";


export const RECEIVE_CURRENT_USER: string = "RECEIVE_CURRENT_USER";
export const RECEIVE_SESSION_ERRORS: string = "RECEIVE_SESSION_ERRORS";
export const RECEIVE_USER_LOGOUT: string = "RECEIVE_USER_LOGOUT";
export const RECEIVE_USER_SIGN_IN: string = "RECEIVE_USER_SIGN_IN";
export const CLEAR_SESSION_ERRORS: string = "CLEAR_SESSION_ERRORS";
export const CHECK_VERIFICATION_EMAIL: string = "CHECK_VERIFICATION_EMAIL";

export const receiveCurrentUser = (currentUser: any) => ({
    type: RECEIVE_CURRENT_USER,
    currentUser
});

export const receiveUserSignIn = () => ({
    type: RECEIVE_USER_SIGN_IN
});

export const receiveErrors = (errors: any) => ({
    type: RECEIVE_SESSION_ERRORS,
    errors
});

export const receiveUserLogout = () => ({
    type: RECEIVE_USER_LOGOUT
});

export const clearSessionErrors = () => ({
    type: CLEAR_SESSION_ERRORS
});

export const checkVerificationEmail = () => ({
    type: CHECK_VERIFICATION_EMAIL
});

export const loginUser = (user: any) => (dispatch: any) => (
    SessionApiUtil.login(user).then(
        user => {
            dispatch(receiveCurrentUser(user.data));
            dispatch(clearSessionErrors());
        },
        errors => {
            debugger;
            console.log(errors);
            if (errors.response.status === 403){
                dispatch(checkVerificationEmail());
            } else {
                dispatch(receiveErrors(errors));
            }
        })
)

export const signupUser = (userData: any) => (dispatch: any) => (
    SessionApiUtil.signup(userData).then(
        res => {
            dispatch(checkVerificationEmail());
            dispatch(clearSessionErrors());
        },
        errors => dispatch(receiveErrors(errors))
    )
)

export const signinUserWithGoogle = (jwt: any) => (dispatch: any) => (
    SessionApiUtil.signinWithGoogle(jwt).then(
        user => {
            dispatch(receiveCurrentUser(user.data));
            dispatch(clearSessionErrors());
        },
        errors => {
            dispatch(receiveErrors(errors));
        }
    )
)

export const logoutUser = (showToast: Function) => (dispatch: any) => (
    SessionApiUtil.logout().then(
        () => {
            dispatch(receiveUserLogout());
            showToast(ToastType.Success,"Successfully logged out.");
        }
        
        )
)