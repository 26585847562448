import axios from 'axios';

export const fetchUserFromUsername = (username: string) => {
    return axios.get(`/api/users/getUserFromUsername/${username}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    });
}

export const updateUserProfilePhoto = (id: number, formData: any) => {
    return axios.patch(`/api/users/${id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json'
        }
    });
}

export const fetchUsers = (page: number) => {
    return axios.get(`/api/users?page=${page}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
}