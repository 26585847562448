import { connect } from "react-redux";
import { clearSessionErrors, signupUser } from "../../actions/session_actions";
import SessionForm from "./session_form";
import { signinUserWithGoogle } from "../../actions/session_actions";

const mapStateToProps = (state:any, props: any) => ({
    errors: state.errors.session,
    checkVerificationEmail: state.session.checkVerificationEmail,
    formType: "Sign Up"
});

const mapDispatchToProps = (dispatch: any) => ({
    processSessionForm: (userData: any) => dispatch(signupUser(userData)),
    clearErrors: () => dispatch(clearSessionErrors()),
    signinUserWithGoogle: (jwt: any) => dispatch(signinUserWithGoogle(jwt))
});

const SignUpFormContainer = connect(mapStateToProps, mapDispatchToProps)(SessionForm);
export default SignUpFormContainer;