import React from "react";
import {navigate} from 'react-router-dom';
import { fetchUserReviews } from "../../../util/review-api-util";
import UserProfilePets from "./user-profile-pets";
import UserProfileReviews from "./user-profile-reviews";
import UserProfileBookmarks from "./user-profile-bookmarks";
import UserProfileVisited from "./user-profile-visited";

class UserProfile extends React.Component {
    constructor(props: any){
        super(props);
        
        this.state = {
            restricted: true,
            tab: "pets",
            userReviews: []
        }

        this.petCard = this.petCard.bind(this);
        this.handleTabClick = this.handleTabClick.bind(this);
        this.renderDetailView = this.renderDetailView.bind(this);
    }
    
    componentDidMount(): void {
        if (this.props.match.params.username === this.props.currentUser.username){
            this.props.fetchUserFromUsername(this.props.match.params.username);
            this.setState({restricted: false});
            fetchUserReviews(this.props.currentUser.id).then( res => {
                this.setState({userReviews: res.data})
                console.log(res.data)
            })
        }
    }

    fullName(user:any){
        if (user){
            return `${user.first_name} ${user.last_name}`;
        }
    }

    monthYear(dateString: string){
        if (dateString){
            const date = new Date(dateString);
            const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
            ];
            const formattedDate = `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
            return formattedDate;
        }
    }

    petCard(pet: any){
        return <div className="user-profile-container-user-pets-card">
            <img src={pet.image_url} alt="" />
            <div className="text">{pet.name}</div>
        </div>
    }

    userReviewCard(review: any){
        return <div className="user-profile-container-user-businesses-right-reviews-content-review">
            <div className="user-profile-container-user-businesses-right-reviews-content-review-left">
                <img src={review.business_image} alt="" />
            </div>
            <div className="user-profile-container-user-businesses-right-reviews-content-review-right">
                <header>{review.business_name}</header>

            </div>
        </div>
    }

    handleTabClick(tab: string){
        return () => this.setState({tab: tab});
    }

    renderDetailView(){
        const pets = [
            {
                id: 1,
                name: "April",
                image_url: "https://encrypted-tbn1.gstatic.com/licensed-image?q=tbn:ANd9GcSQCiW_DxiHWLXKxKeVSaxjQECHIW4Jb1KyQGrY2BHRnV4T5_bj5aDlUNcHvzc0vsdZ_VPkxWD0-OK02YQ",
                age: 13,
                breed: "Cavalier King Charles Spaniel",
                animal: "Dog"
            },
            {
                id: 2,
                name: "April",
                image_url: "https://encrypted-tbn1.gstatic.com/licensed-image?q=tbn:ANd9GcSQCiW_DxiHWLXKxKeVSaxjQECHIW4Jb1KyQGrY2BHRnV4T5_bj5aDlUNcHvzc0vsdZ_VPkxWD0-OK02YQ",
                age: 13,
                breed: "Cavalier King Charles Spaniel",
                animal: "Dog"
            },
            {
                id: 3,
                name: "April",
                image_url: "https://encrypted-tbn1.gstatic.com/licensed-image?q=tbn:ANd9GcSQCiW_DxiHWLXKxKeVSaxjQECHIW4Jb1KyQGrY2BHRnV4T5_bj5aDlUNcHvzc0vsdZ_VPkxWD0-OK02YQ",
                age: 13,
                breed: "Cavalier King Charles Spaniel",
                animal: "Dog"
            }
        ]

        switch(this.state.tab){
            case "pets":
                return <UserProfilePets 
                        firstName={this.props.user.first_name}
                        pets={pets}
                    />;
            case "reviews":
                return <UserProfileReviews
                        reviews = {this.state.userReviews}
                        goToProfile = {(id: number) => this.props.history.push(`/vet-profile/${id}`)}
                    />
            case "bookmarks":
                return <UserProfileBookmarks
                        user = {this.props.user}
                        goToProfile = {(id: number) => this.props.history.push(`/vet-profile/${id}`)}
                        showConfirmationForm = {(message, onSuccess) => this.props.showConfirmationForm(message, onSuccess)}
                    />;
            case "visited":
                return <UserProfileVisited

                />;
            default:
                return null;
        }
    }

    render() {
        if (this.state.restricted){
            return (<div className="user-profile-restricted">
                <div className="user-profile-restricted-icon">
                    <i className="fa-solid fa-lock"></i>
                </div>
                <div>
                    This user either does not exist or has not made their profile page public.
                </div>
            </div>) 
        } else {
            return (<div className="user-profile-container">
                <div className="user-profile-container-left">
                    <body className="user-profile-container-left-snapshot">
                        <div className="user-profile-container-left-snapshot-photo">
                            {this.props.user.photoUrl ? 
                                <img src={this.props.user.photoUrl}></img> :
                                <i className="fa-regular fa-user"></i>}
                        </div>
                        <div className="user-profile-container-left-snapshot-info">
                                <header>{this.fullName(this.props.user)} {this.props.user.is_admin && <i className="fa-solid fa-fire admin-font-color"></i>}</header>
                                <label htmlFor="">@{this.props.user.username}</label>
                                {/* <div>(<span> {this.props.user.username} </span>)</div> */}
                                <div className="info">
                                    <div className="info-header">Member Since:</div>
                                    <div>{this.monthYear(this.props.user.created_at)}</div>
                                </div>
                        </div>
                        {this.props.match.params.username === this.props.currentUser.username ? 
                        <div className="user-profile-container-left-snapshot-options">
                            <div className="user-profile-container-left-snapshot-options-option">
                                <div className="icon-container">
                                    <i className="fa-solid fa-pen-to-square"></i>
                                </div>
                                <div>Edit Profile</div>
                            </div>
                            <div className="user-profile-container-left-snapshot-options-option"
                                onClick={() => this.props.showPhotoForm(this.props.user)}
                            >
                                <div className="icon-container">
                                    <i className="fa-solid fa-user"></i>
                                </div>
                                <div>Add Photo</div>
                            </div>
                        </div> : "" }
                    </body>
                    <body className="user-profile-container-left-tabs">
                        <div 
                            className={`tab ${this.state.tab === "pets" && "active" }`}
                            onClick={this.handleTabClick("pets")}
                        >
                            <i className="fa-solid fa-paw"></i>User Pets
                        </div>
                        <div className="line"></div>
                        <div 
                            className={`tab ${this.state.tab === "reviews" && "active" }`}
                            onClick={this.handleTabClick("reviews")}
                        >
                            <i className="fa-solid fa-star"></i>Reviews
                        </div>
                        <div className="line"></div>
                        <div 
                            className={`tab ${this.state.tab === "bookmarks" && "active" }`}
                            onClick={this.handleTabClick("bookmarks")}
                        >
                            <i className="fa-solid fa-bookmark"></i>Bookmarks
                        </div>
                        <div className="line"></div>
                        <div 
                            className={`tab ${this.state.tab === "visited" && "active" }`}
                            onClick={this.handleTabClick("visited")}
                        >
                            <i className="fa-solid fa-person-walking"></i>Visited
                        </div>
                    </body>

                </div>
                <div className="user-profile-container-right">
                    {this.renderDetailView()}
                </div>
            </div>)
        }
    }
}

export default UserProfile;

