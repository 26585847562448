import * as UserApiUtil from '../util/user_api_util';
import { ToastType } from '../shared/toast.service';
import { closeModal } from './ui-actions';

export const RECEIVE_USER: string = "RECEIVE_USER";
export const RECEIVE_USERS: string = "RECEIVE_USERS";
export const CLEAR_ALL_USERS: string = "CLEAR_ALL_USERS";

export const receiveUser = (user: any) => ({
    type: RECEIVE_USER,
    user
})

export const receiveUsers = (users: any) => ({
    type: RECEIVE_USERS,
    users
})

export const clearAllUsers = (currentUserId: number) => ({
    type: CLEAR_ALL_USERS,
    currentUserId
})

export const fetchUserFromUsername = (username: string) =>  (dispatch: any) => {
    UserApiUtil.fetchUserFromUsername(username).then(user => {
        dispatch(receiveUser(user.data));
    })
}

export const updateUserProfilePhoto = (id: number, formData: any, showToast: Function) => (dispatch: any) => {
    UserApiUtil.updateUserProfilePhoto(id, formData).then((user: any) => {
        dispatch(receiveUser(user.data));
        dispatch(closeModal());
        showToast(ToastType.Success, "Profile Photo sucessfully updated.");
    }, err => {
        showToast(ToastType.Error, "Profile Photo not updated.");
    })
}

export const fetchUsers = (page: number, pagingFunctions: object) => (dispatch: any) => {
    UserApiUtil.fetchUsers(page).then((users: any) => {
        debugger;
        setTimeout(() => {
            pagingFunctions.toggleLoading();
            if (users.data.length > 0){
                dispatch(receiveUsers(users.data));
                pagingFunctions.incrementPage();
            } else {
                debugger;
                pagingFunctions.toggleLoadedAll();
            }
        }, 1000)
    })
} 

// export const fetchBusinesses = (page: number, pagingFunctions: object) => (dispatch: any) => {
//     BusinessApiUtil.fetchBusinesses(page).then((businesses: any) => {
//         setTimeout(() => {
//             pagingFunctions.toggleLoading();
//             if (businesses.data.length > 0){
//                 dispatch(receiveBusinesses(businesses.data));
//                 pagingFunctions.incrementPage();
//             } else {
//                 pagingFunctions.toggleLoadedAll();
//             }
//         }, 10)
//     })
// }