import React from "react"

const UserProfileReviews = (props: any) => {

    const userReviewCard = (review: any) => <li className="user-profile-container-right-reviews-card" key={review.id} >
        <div  className="user-profile-container-right-reviews-card-image">
            <img src={review.business_image} alt="" />
        </div>
        <div className="right">
            <header>
                <div>
                    {review.business_name}
                </div>
                <div>
                    <i className="fa-solid fa-square-arrow-up-right"
                            onClick={() => props.goToProfile(review.business_id)}
                            title="View Business Page"
                    />
                </div>
            </header>
            <div className="ratings">
                <div className="ratings-left">
                    <div className="rating">
                        <div className="label">Recommend: </div>
                        {review.recommend}<i className="fa-solid fa-star"/>
                    </div>
                    <div className="rating">
                        <div className="label">Bedside Manner: </div>
                        {review.bedside}<i className="fa-solid fa-star"/>
                    </div>
                    <div className="rating">
                        <div className="label">Wait TIme: </div>
                        {review.wait}<i className="fa-solid fa-star"/>
                    </div>
                    <div className="rating">
                        <div className="label">Staff Friendliness </div>
                        {review.staff}<i className="fa-solid fa-star"/>
                    </div>
                </div>
                {review?.description?.length > 0 && <div
                    className="ratings-right"
                    >
                        <body>
                            <div className="sides">"</div>
                            <div className="middle">{review.description.length > 150 ? review.description.slice(0,150) + "..." : review.description}</div>
                            <div className="sides">"</div>
                        </body>
                    </div> }
            </div>
        </div>
    </li>

    return <div className="user-profile-container-right-reviews">
        <header>Reviews</header>
        <ul>
            {props.reviews.map((review: any) => userReviewCard(review))}
        </ul>
    </div>
};

export default UserProfileReviews;