import axios from "axios";

export const fetchUserBookmarks = (userId: number) => {
    return axios.get(`/api/users/${userId}/bookmarks`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
}

export const toggleUserBookmark = (ids: any) => {
    return axios.post(`/api/bookmarks`, ids, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
}

export const isBusinessBookmarked = (businessId: number) => {
    return axios.get(`/api/bookmarks/isBusinessBookmarked/${businessId}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
}