import React from 'react';
import { ToastConsumer, ToastProvider } from '../../shared/toast.service';
import ToastService from '../../shared/toast.service';

class BusinessTypeForm extends React.Component {
    constructor(props: any){
        super(props);
        console.log(props);
        this.state = {
            title: this.props.businessType ? "Edit Business Type" : "Create Business Type",
            businessType: this.props.businessType ? this.props.businessType.business_type : "",
            icon: this.props.businessType ? this.props.businessType.icon : "",
            formType: this.props.businessType ? "Update Business Type" : "Create Business Type"
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInput(type: any){
        return (e) => {
            this.setState({ [type]: e.currentTarget.value})
        }
    }

    handleSubmit(e: any){
        e.preventDefault();
        if (this.props.businessType){
            let business_type: any = {business_type: {}};
            business_type.business_type.id = this.props.businessType.id; 
            business_type.business_type.business_type = this.state.businessType;
            business_type.business_type.icon = this.state.icon;
            // TODO: Conditionally determine whether or not update or create action is used based on if props.id exists
            this.props.updateBusinessTypes(business_type, this.props.showToast);
        }
    }

    render() {
        return <div className='modal-container-animal-form'>
            <header>{this.state.title}</header>
            <form onSubmit={this.handleSubmit}>
                <label>
                    <div>Business Type</div>
                    <input type="text" 
                        value={this.state.businessType}
                        onChange={this.handleInput("businessType")}
                    />
                </label>
                <label>
                    <div>Icon</div>
                    <input type="text" 
                        value={this.state.icon} 
                        onChange={this.handleInput("icon")}
                    />
                </label>
                <div className='icon-container'>
                    <div className='modal-container-animal-form-icon'>
                        <i className={this.state.icon}></i>
                        <label>{this.state.businessType}</label>
                    </div>
                </div>
                <div className='buttons'>
                    <input 
                        type="submit"
                        value={this.state.formType}
                        className="submit"
                    />
                    {this.props.businessType ? <button>
                        Delete Business Type
                    </button> : ""}
                    
                </div>
            </form>
        </div>
    }
}

const BusinessTypeFormWithToast = (props: any) => (
    <ToastConsumer>
    {(showToast) => <BusinessTypeForm showToast={showToast} {...props} />}
    </ToastConsumer>
);

export default BusinessTypeFormWithToast;