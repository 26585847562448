import axios from 'axios';

export const fetchReview = (user_id, business_id) => {
    return axios.get(`/api/user-review?user_id=${user_id}&business_id=${business_id}`);
};

export const fetchUserReviews = (user_id: number) => {
    return axios.get(`/api/users/${user_id}/reviews`,{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    });
}

export const createReview = (review: any) => {
    return axios.post(`/api/reviews`, review, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
}

export const updateReview = (review: any) => {
    return axios.patch(`/api/reviews/${review.review.id}`, review, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
}