import React from "react";
import Footer from "../footer/footer";

class AboutUs extends React.Component {
    constructor(props: any){
        super(props);
    }

    render(){
        return (<div className="about-us-container">
            ABOUT US
            <Footer/>
        </div>)
    }
}

export default AboutUs