import ConfirmationFormWithToast from "./confirmation-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { closeModal } from "../../actions/ui-actions";

const mapStateToProps = (state: any, ownProps: any) => ({
    currentUser: state.entities.users[state.session.id]
});

const mapDispatchToProps = (dispatch: any) => ({
    closeModal: () => dispatch(closeModal())
});

const ConfirmationFormContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmationFormWithToast));
export default ConfirmationFormContainer;