import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import React from "react";
import { 
    ADMIN_OPEN_ANIMALS_FORM,
    ADMIN_OPEN_BUSINESS_TYPES_FORM,
    ADMIN_OPEN_SERVICES_FORM,
    OPEN_REVIEW_MODAL,
    OPEN_BUSINESS_MODAL,
    SHOW_PHOTO_FORM,
    SHOW_CONFIRMATION_FORM,
    closeModal } from "../../actions/ui-actions";
import AnimalFormContainer from "./animal-form-container";
import ReviewForm from "./review-form";
import BusinessFormContainer from "./business-form-container";
import BusinessTypeFormContainer from "./business-type-form-container";
import ServiceFormContainer from "./service-form-container";
import ReviewFormContainer from "./review-form-container";
import PhotoFormContainer from "./photo-form-container";
import ConfirmationFormContainer from "./confirmation-form-container";

class Modal extends React.Component {

    constructor(props: any){
        super(props);
        this.backgroundCloseModal = this.backgroundCloseModal.bind(this);
        this.modalContent = this.modalContent.bind(this);
    }

    backgroundCloseModal(e){
        if (e.target === e.currentTarget && !this.props.modalContent?.business){
            this.props.closeModal();
        }
    }

    modalContent(modalContent: any){
        switch(modalContent.type){
            case ADMIN_OPEN_ANIMALS_FORM:
                return <AnimalFormContainer animal={modalContent.animal}/>
            case ADMIN_OPEN_BUSINESS_TYPES_FORM:
                return <BusinessTypeFormContainer businessType={modalContent.businessType}/>
            case ADMIN_OPEN_SERVICES_FORM:
                return <ServiceFormContainer service={modalContent.service} />
            case OPEN_REVIEW_MODAL:
                return <ReviewFormContainer review={modalContent.review}/>
            case OPEN_BUSINESS_MODAL:
                return <BusinessFormContainer business={modalContent.business}/>
            case SHOW_PHOTO_FORM:
                return <PhotoFormContainer user={modalContent.user}/>
            case SHOW_CONFIRMATION_FORM:
                return <ConfirmationFormContainer 
                    message={modalContent.message}
                    onConfirm={modalContent.onConfirm}
                />
            default: 
                return null;
        }
    }

    render(){
        return <div className={`modal-container 
            ${this.props.modalVisible ? "modal-container-visible" : "modal-container-hidden"}`}
            onClick={this.backgroundCloseModal}
        >
            <div className="modal-container-close"
                onClick={this.props.closeModal}
            >
                <i className="fa-solid fa-xmark"></i>
            </div>
            {this.props.modalContent ? this.modalContent(this.props.modalContent) : ""}
        </div>
    }
}

const mapStateToProps = (state: any, ownProps:any) => ({
    modalVisible: state.ui.modalVisible,
    modalContent: state.ui.modalContent
});

const mapDispatchToProps = (dispatch: any) => ({
    closeModal: () => dispatch(closeModal()),
})

const ModalContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(Modal));
export default ModalContainer;