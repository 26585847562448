import { 
    ADMIN_OPEN_ANIMALS_FORM, 
    ADMIN_OPEN_BUSINESS_TYPES_FORM,
    ADMIN_OPEN_SERVICES_FORM,
    CLOSE_MODAL, 
    OPEN_REVIEW_MODAL,
    OPEN_BUSINESS_MODAL,
    SHOW_PHOTO_FORM,
    SHOW_CONFIRMATION_FORM
} from "../actions/ui-actions";

const preloadedState = {
    modalVisible: false,
    modalContent: null
}

export default (state = preloadedState, action: any) => {
    Object.freeze(state);
    let nextState = Object.assign({}, state);

    switch(action.type){
        case ADMIN_OPEN_ANIMALS_FORM:
            nextState.modalVisible = true;
            nextState.modalContent = {
                type: ADMIN_OPEN_ANIMALS_FORM,
                animal: action.animal
            }
            return nextState;

        case ADMIN_OPEN_BUSINESS_TYPES_FORM:
            nextState.modalVisible = true;
            nextState.modalContent = {
                type: ADMIN_OPEN_BUSINESS_TYPES_FORM,
                businessType: action.businessType
            }
            return nextState;

        case ADMIN_OPEN_SERVICES_FORM:
            nextState.modalVisible = true;
            nextState.modalContent = {
                type: ADMIN_OPEN_SERVICES_FORM,
                service: action.service
            }
            return nextState;

        case SHOW_PHOTO_FORM:
            nextState.modalVisible = true;
            nextState.modalContent = {
                type: SHOW_PHOTO_FORM,
                user: action.user
            };
            return nextState;
            
        case SHOW_CONFIRMATION_FORM:
            nextState.modalVisible = true;
            nextState.modalContent = {
                type: SHOW_CONFIRMATION_FORM,
                message: action.message,
                onConfirm: action.onConfirm
            }
            return nextState;
            
        case OPEN_REVIEW_MODAL:
            nextState.modalVisible = true;
            nextState.modalContent = {
                type: OPEN_REVIEW_MODAL,
                review: action.review
            }
            return nextState;

        case OPEN_BUSINESS_MODAL:
            nextState.modalVisible = true;
            nextState.modalContent = {
                type: OPEN_BUSINESS_MODAL,
                business: action.business
            }
            return nextState;

        case CLOSE_MODAL:
            nextState.modalVisible = false;
            nextState.modalContent = null;
            return nextState;
            
        default:
            return state;
    }
}