import { RECEIVE_SEARCHED_BUSINESSES, CLEAR_ALL_BUSINESSES } from "../../actions/business-actions";

export default (state = {}, action: any) => {
    Object.freeze(state);
    let nextState: any = Object.assign({}, state)

    switch(action.type){
        case RECEIVE_SEARCHED_BUSINESSES:
            action.searchedBusinesses.forEach((searchedBusiness: any, index: number) => {
                nextState[index] = searchedBusiness;
            })
            return nextState;

        case CLEAR_ALL_BUSINESSES:
            return {};
            
        default:
            return state;
    }
}