import { RECEIVE_BUSINESS_TYPE, RECEIVE_BUSINESS_TYPES } from "../../actions/business-type-actions";

export default (state = {}, action: any) => {
    Object.freeze(state);
    let nextState: any = Object.assign({}, state)

    switch(action.type){
        case RECEIVE_BUSINESS_TYPE:
            nextState[action.businessType.id] = action.businessType;
            return nextState;
        case RECEIVE_BUSINESS_TYPES:
            action.businessTypes.forEach((businessType: any) => {
                nextState[businessType.id] = businessType;
            })
            return nextState;
        default:
            return state;
    }
}