import { RECEIVE_REVIEW, RECEIVE_REVIEWS } from "../actions/review-actions";

export default (state = {}, action: any)=>{
    Object.freeze(state);
    let nextState: any = Object.assign({}, state)

    switch(action.type){
        case RECEIVE_REVIEW:
            nextState[action.review.id] = action.review;
            return nextState;
        case RECEIVE_REVIEWS:
            action.reviews.forEach((review: any) => {
                nextState[review.id] = review;
            })
            return nextState;
        default:
            return state;
    }
}
