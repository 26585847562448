import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { showReviewModal } from "../../../actions/ui-actions";
import { fetchBusiness } from "../../../actions/business-actions";
import { fetchUserReview } from "../../../actions/review-actions";
import VetProfileWithToast from "./vet-profile";
import { showBusinessModal } from "../../../actions/ui-actions";

const mapStateToProps = (state: any, ownProps: any) => ({
    vetId: ownProps.match.params.vetId,
    business: state.entities.businessRoot.businesses[ownProps.match.params.vetId],
    currentUser: state.entities.users[state.session.id],
    reviews: state.entities.reviews
})

const mapDispatchToProps = (dispatch: any) => ({
    showReviewModal: (review: any) => dispatch(showReviewModal(review)),
    fetchBusiness: (id: number, businessProfileFunctions: object) => dispatch(fetchBusiness(id, businessProfileFunctions)),
    fetchUserReview: (user_id: number, business_id: number) => dispatch(fetchUserReview(user_id, business_id)),
    showBusinessModal: (business: any) => dispatch(showBusinessModal(business))
});

const VetProfileContainer = withRouter(connect(mapStateToProps,mapDispatchToProps)(VetProfileWithToast));
export default VetProfileContainer;
