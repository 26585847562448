import * as ReviewApiUtil from '../util/review-api-util';
import { ToastType } from '../shared/toast.service';
import { closeModal } from './ui-actions';

export const RECEIVE_REVIEW = "RECEIVE_REVIEW";
export const RECEIVE_REVIEWS = "RECEIVE_REVIEWS";

export const receiveReview = (review: any) => ({
    type: RECEIVE_REVIEW,
    review
})

export const receiveReviews = (reviews: any) => ({
    type: RECEIVE_REVIEWS,
    reviews
})



export const fetchUserReview = (user_id: number, business_id: number) => (dispatch: any) => {
    ReviewApiUtil.fetchReview(user_id, business_id).then(review => {
        dispatch(receiveReview(review.data));
    })
}

export const createReview = (review: any, showToast: Function) => (dispatch: any) => {
    ReviewApiUtil.createReview(review).then(review => {
        dispatch(receiveReview(review.data));
        showToast(ToastType.Success,"Review successfully created.");
    }, err => {
        showToast(ToastType.Error,"Review not updated.");
    })
}

export const updateReview = (review: any, showToast: Function) => (dispatch: any) => {
    ReviewApiUtil.updateReview(review).then(review => {
        dispatch(receiveReview(review.data));
        dispatch(closeModal());
        showToast(ToastType.Success,"Review successfully updated.");
    }, err => {
        showToast(ToastType.Error,"Review not updated.");
    })
}