import React from "react";
import { Link } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import jwtDecode from "jwt-decode";

class SessionForm extends React.Component {

    constructor(props: any){
        super(props);
        this.state = {
            username: "",
            password: ""
        }
        if (this.props.formType === "Sign Up"){
            this.setState({email: ""});
            this.setState({firstName: ""});
            this.setState({lastName: ""});
            // this.setState({password2: ""});
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.askToVerifyEmail = this.askToVerifyEmail.bind(this);
        this.loginErrors = this.loginErrors.bind(this);
        this.handleCallbackResponse = this.handleCallbackResponse.bind(this);
        
    }

    componentDidMount(): void {
        setTimeout(() => {

            google.accounts.id.initialize({
                client_id: window.frontendEnv,
                callback: this.handleCallbackResponse
            })
    
            google.accounts.id.renderButton(
                document.getElementById("signInDiv"),
                {theme: "outline", size: "large"}
            )
        }, 0)
    }

    handleCallbackResponse(response){
        var userObject = jwtDecode(response.credential);
        this.props.signinUserWithGoogle(response.credential);
        console.log(userObject)
    }

    handleInput(type: any){
        return (e) => {
            this.setState({ [type]: e.currentTarget.value})
        }
    }

    handleSubmit(e: any){
        e.preventDefault();
        this.props.clearErrors();
        const user = {user: this.state};
        this.props.processSessionForm(user);
    }

    sessionErrors(field: string){
        let fieldError;
        Object.values(this.props.errors).forEach(error => {
            if (error.includes(field)){
                fieldError = error;
            }
        })
        if (fieldError?.length > 0){
            return <div className="session-error">{fieldError}</div>
        }
    }

    askToVerifyEmail(){
        if (this.props.checkVerificationEmail){
            return (<div className="below-submit verify-email">
                Please check your email for the verification link.
            </div>)
        }
    }

    loginErrors(){
        let loginError = null;
        Object.values(this.props.errors).forEach(error => {
            if (error.includes("credential")){
                loginError = error;
            }
        })
        if (loginError){
            return (<div className="below-submit session-error">
                {loginError}
            </div>)
        }
    }

    render(){
        return <div className="session-form-container">
            <div className="session-form-container-left">
                <div className="phone-logo">
                    <img src={window.logoVerticalText} alt="Logo" />
                </div>
                <form 
                    className="session-form-container-left-form"
                    onSubmit={this.handleSubmit} 
                >
                    <label>Username:
                        <input 
                            type="text"
                            value={this.state.username}
                            onChange={this.handleInput("username")}
                        />
                        {this.sessionErrors("Username")}
                    </label>
                    {this.props.formType === "Sign Up" ? 
                        <label>Email:
                            <input 
                                type="text" 
                                value={this.state.email} 
                                onChange={this.handleInput('email')}
                            />
                            {this.sessionErrors("Email")}
                        </label>
                    : ""}
                    {this.props.formType === "Sign Up" ? 
                        <label>First Name:
                            <input 
                                type="text" 
                                value={this.state.firstName} 
                                onChange={this.handleInput('firstName')}
                            />
                            {this.sessionErrors("First name")}
                        </label>
                    : ""}
                    {this.props.formType === "Sign Up" ? 
                        <label>Last Name:
                            <input 
                                type="text" 
                                value={this.state.lastName} 
                                onChange={this.handleInput('lastName')}
                            />
                            {this.sessionErrors("Last name")}
                        </label>
                    : ""}
                    <label>Password:
                        <input 
                            type="password" 
                            value={this.state.password}
                            onChange={this.handleInput('password')}
                        />
                        {this.sessionErrors("Password")}
                    </label>
                    <input 
                        type="submit"
                        value={this.props.formType}
                        className="submit"
                    />
                </form>
                {this.askToVerifyEmail()}
                {this.loginErrors()}
                <div id="signInDiv"></div>
                {this.props.formType === "Log In" ? 
                    <div className="session-form-bottom sign-up">Don't have an account? 
                        <Link onClick={()=> this.props.clearErrors()} to="/user-signup">Sign Up! </Link></div> :
                    <div className="session-form-bottom sign-up">Already have an account? 
                        <Link onClick={()=> this.props.clearErrors()} to="/user-login">Log In! </Link></div>
                }
                <Link 
                    onClick={()=> this.props.clearErrors()}
                    className="session-form-bottom back-to-home" to="/">
                    Back to Home
                </Link>
            </div>
            <div className="session-form-container-right">
                <img src={window.logoVerticalText} alt="Logo" />
            </div>
        </div>
    }
}

export default SessionForm;