import * as AnimalApiUtil from "../util/animal-api-util";
import { ToastType } from "../shared/toast.service";

export const RECEIVE_ANIMAL = "RECEIVE_ANIMAL";
export const RECEIVE_ANIMALS = "RECEIVE_ANIMALS";

export const receiveAnimals = (animals: any) => ({
    type: RECEIVE_ANIMALS,
    animals
})

export const receiveAnimal = (animal: any) => ({
    type: RECEIVE_ANIMAL,
    animal
})

export const fetchAnimalTypes = () => (dispatch: any) => {
    AnimalApiUtil.fetchAnimalTypes().then(animals => {
        dispatch(receiveAnimals(animals.data));
    })
}

export const updateAnimalTypes = (animal: any, showToast: Function) => (dispatch: any) => {
    AnimalApiUtil.updateAnimalTypes(animal).then(animal => {
        dispatch(receiveAnimal(animal.data));
        showToast(ToastType.Success,"Animal successfully updated.");
    }, err => {
        showToast(ToastType.Error,"Animal not updated.");
    })
}