import React from "react";
import { Link } from "react-router-dom";
import { ToastConsumer, ToastProvider } from '../../shared/toast.service';
import ToastService from '../../shared/toast.service';

class NavBar extends React.Component {

    constructor(props: any){
        debugger;
        super(props);
        this.state = {
            settingsVisible: false,
            searchQuery: "",
            mobileMenuVisible: false
        };
        this.sessionButtons = this.sessionButtons.bind(this);
        this.navbarOptions = this.navbarOptions.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
        this.mobileMenu = this.mobileMenu.bind(this);
        this.handleWindowResize = this.handleWindowResize.bind(this);
        this.mobileSettingsDropdown = this.mobileSettingsDropdown.bind(this);
        this.settingsDropdown = this.settingsDropdown.bind(this);
    }

    componentDidMount() {
        document.addEventListener("click", this.handleOutsideClick);
        window.addEventListener("resize", this.handleWindowResize);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleOutsideClick);
        window.removeEventListener("resize", this.handleWindowResize);
    }

    toggleMobileMenu = () => {
        this.setState((prevState) => ({
          mobileMenuVisible: !prevState.mobileMenuVisible,
        }));
    };

    sessionButtons(){
        if (!this.props.loggedIn){
            return (<div className='session-buttons'>
                <Link className="claim-your-clinic-btn btn" to="/claim-your-clinic">Claim Your Clinic</Link>
                <Link className="pettalks-btn btn" to="/pet-talks">Pet Talks</Link>
                <Link className="about-us-btn btn" to="/about-us">About Us</Link>
                <Link className="session-button session-button-sign-in login-button btn" to="/user-login">Sign In</Link>
                {/* <Link className="session-button " to="/user_signup">Sign Up</Link> */}
            </div>)
        }
    }

    navbarOptions(){
        if (this.props.loggedIn && this.props.currentUser){
            return <div className="navbar-options">
                <Link className="claim-your-clinic-btn btn" to="/claim-your-clinic">Claim Your Clinic</Link>
                <Link className="pettalks-btn btn" to="/pet-talks">Pet Talks</Link>
                <Link className="about-us-btn btn" to="/about-us">About Us</Link>
                <i 
                    className="fa-solid fa-gear settings-btn"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.setState({settingsVisible: !this.state.settingsVisible});
                        setTimeout(() => console.log(this.state.settingsVisible), 0);
                    }}
                ></i>
            </div>
        }
    }

    mobileMenu(){
        return <div className={`mobile-menu ${this.state.mobileMenuVisible && 'mobile-menu-active'}`}>
            
            <div className="mobile-menu-top">
                <img src={window.logo} alt="Logo" />
                <i className="fa-solid fa-xmark mobile-menu-icon"
                    onClick={() => {this.setState({mobileMenuVisible: false})}}
                ></i>
            </div>
            <div className="mobile-menu-bottom">
                <div className="mobile-menu-item">
                    <Link className="claim-your-clinic-btn btn" to="/claim-your-clinic">Claim Your Clinic</Link>
                </div>
                <div className="mobile-menu-item">
                    <Link className="pettalks-btn btn" to="/pet-talks">Pet Talks</Link>
                </div>
                <div className="mobile-menu-item">
                    <Link className="about-us-btn btn" to="/about-us">About Us</Link>
                </div>
                {this.props.loggedIn && this.props.currentUser ? <div className="mobile-menu-item settings"
                    onClick={(e) => {
                        debugger;
                        e.preventDefault();
                        e.stopPropagation();
                        this.setState({settingsVisible: !this.state.settingsVisible});
                    }
                    }
                >
                    <i 
                        className={this.state.settingsVisible ?  "fa-solid fa-caret-down" :"fa-solid fa-caret-right"}
                    ></i>
                    Settings
                </div> : <div className="mobile-menu-item">
                    <Link className="claim-your-clinic-btn btn" to="/user-login">Log In</Link>
                </div> }
                {this.state.settingsVisible ?  this.mobileSettingsDropdown() : ""}
            </div>
        </div>
    }


    settingsDropdown(){
        if (this.props.loggedIn && this.state.settingsVisible){
            // return (<div className={dropdownClassName}>
            return (<div className="settings-dropdown navbar-right-settings-dropdown show">
                <Link to={`/user-profile/${this.props.currentUser.username}`}
                    className="settings-dropdown-option">
                    <i className="fa-solid fa-user"></i>
                    Profile Settings
                </Link>
                {this.props.currentUser.is_admin ? 
                    <Link to="/admin-overview"
                        className="settings-dropdown-option admin-button">
                        <i className="fa-solid fa-hammer"></i>
                        Admin Settings
                    </Link> : ""}
                <button className="settings-dropdown-logout" onClick={() => this.props.logout(this.props.showToast)}>
                    <div>
                        <i className="fa-solid fa-right-from-bracket"></i>Log Out
                    </div>
                </button>
            </div>)
        }
    }

    mobileSettingsDropdown(){
        return (<div className="mobile-menu-settings-dropdown">
            <Link to={`/user-profile/${this.props.currentUser.username}`}
                    className="mobile-menu-item sub-item">
                    <i className="fa-solid fa-user"></i>
                    Profile Settings
            </Link>
            {this.props.currentUser.is_admin ? 
                    <Link to="/admin-overview"
                        className="mobile-menu-item admin-button">
                        <i className="fa-solid fa-hammer"></i>
                        Admin Settings
            </Link> : ""}
        </div>)
    }


    handleOutsideClick(event) {
        if ( this.state.settingsVisible && !event.target.classList.contains("settings-btn")) {
            this.setState({ settingsVisible: false });
        }
    }

    handleWindowResize(){
        this.setState({mobileMenuVisible: false, settingsVisible: false});
    }
    


    render(){
        return (
            <div className="navbar-container">
                <div className="navbar">
                    <div className="navbar-left">
                        <Link to="/">
                            <img src={window.logoBanner} alt="Logo" />
                        </Link>
                        <form className="navbar-left-searchbar">
                            <input type="text"  placeholder="Search..."/>
                            <button type="submit"><i className="fa fa-search"></i></button>
                        </form>
                    </div>
                    <div className="navbar-right">
                        <div className="mobile-menu-icon" onClick={this.toggleMobileMenu}>
                            <i className="fa-solid fa-bars"></i>
                        </div>
                        <div className="navbar-right-desktop">
                            {this.sessionButtons()}
                            {this.navbarOptions()}
                        </div>
                        {this.mobileMenu()}
                    </div>
                </div>
                {this.settingsDropdown()}
            </div>
        )
    }
}

const NavBarWithToast = (props: any) => (
    <ToastConsumer>
    {(showToast) => <NavBar showToast={showToast} {...props} />}
    </ToastConsumer>
);

export default NavBarWithToast;
