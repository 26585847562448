import React from "react";
import ReactDOM from "react-dom";
import Root from "./components/root";
import { createRoot } from 'react-dom/client';
import configureStore from "./store/store";
import { loginUser, logoutUser, signupUser } from "./actions/session_actions";
import { fetchUserReviews } from "./util/review-api-util";
import { fetchUsers } from "./util/user_api_util";

document.addEventListener("DOMContentLoaded", () => {

    let store;
    console.log(window.currentUser);
    debugger;
    if (window.currentUser) {
        const preloadedState = {
            entities: {
                users: {[window.currentUser.id]: window.currentUser}
            },
            session: {id: window.currentUser.id}
        };
        store = configureStore(preloadedState);
        delete window.currentUser;
    } else {
        store = configureStore()
    }
    window.fetchUsers = fetchUsers;
    window.store = store;
    window.user = {user: {username: "username", password: "password"}};
    window.loginUser = loginUser;
    window.logoutUser = logoutUser;
    window.signupUser = signupUser;
    const domNode = document.getElementById('root')!;
    const root = createRoot(domNode);
    root.render(<Root store={store}/>);
    {/* // ReactDOM.render(<Root store={store}/>, root); */}
});