import { RECEIVE_BUSINESS, RECEIVE_BUSINESSES, CLEAR_ALL_BUSINESSES } from "../../actions/business-actions";

export default (state = {}, action: any)=>{
    Object.freeze(state);
    let nextState: any = Object.assign({}, state)

    switch(action.type){
        case RECEIVE_BUSINESS:
            nextState[action.business.id] = action.business;
            return nextState;
        case RECEIVE_BUSINESSES:
            action.businesses.forEach((business: any) => {
                nextState[business.id] = business;
            })
            return nextState;
        case CLEAR_ALL_BUSINESSES:
            return {};
            
        default:
            return state;
    }
}
