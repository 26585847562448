import React from "react";
import { ToastConsumer, ToastProvider } from '../../shared/toast.service';
import ToastService from '../../shared/toast.service';

class ConfirmationForm extends React.Component {

    constructor(props: any){
        super(props);
    }

    render(){
        return <div className='modal-container-animal-form'>
        <header>Confirmation Dialog</header>
        <div>{this.props.message}</div>
        <button
            onClick={() => {
                this.props.onConfirm();
                this.props.closeModal();
            }}
        >
            Confirm
        </button>
        <button
            onClick={this.props.closeModal}
        >
            Cancel
        </button>
    </div>
    }
}

const ConfirmationFormWithToast = (props: any) => (
    <ToastConsumer>
    {(showToast) => <ConfirmationForm showToast={showToast} {...props} />}
    </ToastConsumer>
);

export default ConfirmationFormWithToast;