import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import LaunchPage from "./launch_page";
import { fetchServices } from "../../actions/service-actions";

const mapStateToProps = (state: any, ownProps: any) => ({
    services: state.entities.services
})

const mapDispatchToProps = (dispatch: any) => ({
    fetchServices: () => dispatch(fetchServices())
});

const LaunchPageContainer = withRouter(connect(mapStateToProps,mapDispatchToProps)(LaunchPage));
export default LaunchPageContainer;