import { RECEIVE_CURRENT_USER, RECEIVE_USER_LOGOUT, CHECK_VERIFICATION_EMAIL} from "../actions/session_actions";

const _nullSession = {
    id: null,
    checkVerificationEmail: false
}

export default (state = _nullSession, action: any) => {
    Object.freeze(state);
    let nextState = Object.assign({}, state);

    switch(action.type){
        case RECEIVE_CURRENT_USER:
            return Object.assign({}, {id: action.currentUser?.id, checkVerificationEmail: false});
        case RECEIVE_USER_LOGOUT:
            return _nullSession;
        case CHECK_VERIFICATION_EMAIL:
            nextState.checkVerificationEmail = true;
            return nextState;
        default:
            return state;
    }
}