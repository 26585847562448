import React from "react";
import { ToastConsumer } from "../../shared/toast.service";

class ReviewForm extends React.Component{

    constructor(props: any){
        super(props);
        console.log(this.props.review)
        this.state={
            recommendRating: this.props.review?.recommend || 0,
            bedsideMannerRating: this.props.review?.bedside || 0,
            friendlyStaffRating: this.props.review?.staff || 0,
            waitTimeRating: this.props.review?.wait || 0,
            experience: this.props.review?.description || ""
        }

        this.setRating = this.setRating.bind(this);
        this.starLabel = this.starLabel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    starLabel(rating: string){
        switch(rating){
            case "recommendRating":
                return <label className="head-label">
                    <div>Would you recommend</div>
                    <header>{this.props.review.businessName}</header>
                </label>
            case "bedsideMannerRating":
                return <label>Bedside Manner</label>
            case "friendlyStaffRating":
                return <label>Friendly Staff</label>
            case "waitTimeRating":
                return <label>Wait Time</label>
            default:
                return null
        }
    }

    setRating(rating: string, starCount: number){
        this.setState(prevState => ({
            [rating]: starCount,
          }));
    }

    handleInput(type){
        return (e) => {
            this.setState({[type]: e.currentTarget.value})
        }
    }

    handleSubmit(e: any){
        e.preventDefault();

        const {bedsideMannerRating, experience, friendlyStaffRating, recommendRating, waitTimeRating} = this.state;

        const review = {
            id: this.props.review.id,
            bedside: bedsideMannerRating,
            description: experience,
            staff: friendlyStaffRating,
            recommend: recommendRating,
            wait: waitTimeRating,
            user_id: this.props.currentUser.id,
            business_id: this.props.review.business_id
        }

        if (this.props.review.id){
            this.props.updateReview({review: review}, this.props.showToast);
        } else {
            this.props.createReview({review: review}, this.props.showToast);
        }
        console.log(review);
    }

    render(){

        const ratings = ["recommendRating", "bedsideMannerRating", "friendlyStaffRating", "waitTimeRating"];

        return <div className="modal-container-review-form">
            <label className="italic">Please select how many stars you would give for each category.</label>
            {ratings.map(rating => {
                return <div key={rating} className="rating-container">
                    {this.starLabel(rating)}
                    <div className="starRating">
                        <i className={`fa-solid fa-star ${this.state[rating] >= 1 ? 'gold' : ""}`}
                            onClick={() => this.setRating(rating, 1)}
                        ></i>
                        <i className={`fa-solid fa-star ${this.state[rating] >= 2 ? 'gold' : ""}`}
                            onClick={() => this.setRating(rating, 2)}
                        ></i>
                        <i className={`fa-solid fa-star ${this.state[rating] >= 3 ? 'gold' : ""}`}
                            onClick={() => this.setRating(rating, 3)}
                        ></i>
                        <i className={`fa-solid fa-star ${this.state[rating] >= 4 ? 'gold' : ""}`}
                            onClick={() => this.setRating(rating, 4)}
                        ></i>
                        <i className={`fa-solid fa-star ${this.state[rating] >= 5 ? 'gold' : ""}`}
                            onClick={() => this.setRating(rating, 5)}
                        ></i>
                    </div>

                </div>
            })}
            <label className="left">Tell us more about your experience!</label>
            <textarea
                value={this.state.experience}
                onChange={this.handleInput("experience")}
            />
            <button
                onClick={this.handleSubmit}
            >
                Submit Review
            </button>

        </div>
    }
}

const ReviewFormWithToast = (props: any) => (
    <ToastConsumer>
    {(showToast) => <ReviewForm showToast={showToast} {...props} />}
    </ToastConsumer>
);

export default ReviewFormWithToast;