import React from "react";

class AdminBusinesses extends React.Component{

    containerRef: React.RefObject<HTMLDivElement>;

    constructor(props: any){
        super(props);

        this.props.clearAllBusinesses();

        this.state = {
            page: 0,
            isLoading: true,
            loadedAll: false
        }

        this.handleClick = this.handleClick.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
        this.incrementPage = this.incrementPage.bind(this);
        this.toggleLoadedAll = this.toggleLoadedAll.bind(this);
        this.containerRef = React.createRef<HTMLDivElement>();
    }

    componentDidMount(): void {
        const pagingFunctions = {
            incrementPage: this.incrementPage,
            toggleLoading: this.toggleLoading,
            toggleLoadedAll: this.toggleLoadedAll
        } 
        this.props.fetchBusinesses(this.state.page, pagingFunctions);

        const container = document.querySelector('.body'); 
        container.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        const container = document.querySelector('.body'); 
        container.removeEventListener('scroll', this.handleScroll);
    }

    toggleLoading(){
        this.setState({isLoading: false});
    }

    toggleLoadedAll(){
        this.setState({loadedAll: true});
    }

    incrementPage(){
        this.setState({page: this.state.page + 1})
    }

    handleScroll() {
        const container = document.querySelector('.body'); 
        const { scrollTop, clientHeight, scrollHeight } = container;

        if (scrollTop + clientHeight + 5 >= scrollHeight && !this.state.loadedAll && !this.state.isLoading) {
            this.setState({isLoading: true});
            const pagingFunctions = {
                incrementPage: this.incrementPage,
                toggleLoading: this.toggleLoading,
                toggleLoadedAll: this.toggleLoadedAll
            } 
            this.props.fetchBusinesses(this.state.page + 1, pagingFunctions);
        }
    }

    handleClick(e){
        e.preventDefault();
        this.props.showBusinessModal();
    }

    formatPhoneNumber(phoneNumber: string): string {
        const digitsOnly = phoneNumber.replace(/\D/g, '');
        const areaCode = digitsOnly.slice(0, 3);
        const firstPart = digitsOnly.slice(3, 6);
        const secondPart = digitsOnly.slice(6);
        const formattedPhoneNumber = `(${areaCode})-${firstPart}-${secondPart}`;
        return formattedPhoneNumber;
    }

    shortenUrl(url: string){
        url = url.slice();

        const prefix = "https://";
        if (url.startsWith(prefix)) {
            url = url.substring(prefix.length);
        }

        if (url.length < 39) {
            return url;
        }

        return url.slice(0,35) + "...";
    }

    businessCard(business: any, index: any){
        return <li className="admin-businesses-container-business-card">
            <div className="admin-businesses-container-business-card-left">
                {business.image_url ? <img src={business.image_url} alt=""/> : <i className="fa-solid fa-user"></i>}
            </div>
            <div className="admin-businesses-container-business-card-right">
                <div className="admin-businesses-container-business-card-right-info">
                    <header>{index + 1}. {business.name}</header>
                    <div className='vet-profile-container-banner-info-type'><i className="fa-solid fa-hospital"></i>{business.business_type.business_type}</div>
                        <div className='vet-profile-container-banner-info-address'>
                            <i className="fa-solid fa-location-dot"></i>
                            {business.address}
                        </div>
                        <div className='vet-profile-container-banner-info-rating'>
                            <i className="fa-solid fa-star"></i>{business.rating}({business.reviewCount} Reviews)
                        </div>
                        <div className='vet-profile-container-banner-info-services'>
                            <i className="fa-solid fa-notes-medical"></i>
                            <div className="services-list">
                                {business.services.map(service => service.name).join(", ")}
                            </div>
                        </div>
                        <div className='vet-profile-container-banner-info-phone'>
                            <i className="fa-solid fa-phone"></i>
                            <a href={`tel:+${business.phone}`}>{this.formatPhoneNumber(business.phone)}</a>
                        </div>
                        <div className='website'>
                            <i className="fa-solid fa-globe"></i>
                            <a href={business.website} target="_blank">{this.shortenUrl(business.website)}</a>
                        </div>
                    {/* </div> */}

                </div>
                <div className="admin-businesses-container-business-card-right-icons">
                    <i className="fa-solid fa-gear"
                        onClick={()=> this.props.showBusinessModal(business)}
                        title="Edit Business"
                    ></i>
                    <i className="fa-solid fa-square-arrow-up-right"
                        onClick={() => this.props.history.push(`/vet-profile/${business.id}`)}
                        title="View Business Page"
                    >
                    </i>
                    <i className="fa-solid fa-trash-can red"
                        onClick={() => {
                            // TODO: Add functionality to send a delete request. 
                        }}
                        title="Delete Business"
                    ></i>
                </div>
            </div>
        </li>
    }

    render(){
        return <div className="admin-businesses-container"
            ref={this.containerRef}
        >
            <div className="top-button">
                <button
                    onClick={() => this.props.history.push('/admin-overview')}
                    className="admin-businesses-container-head-buttons back"
                >
                    <i className="fa-solid fa-arrow-left"></i> Back to Admin Overview
                </button>
                <button
                    onClick={this.handleClick}
                    className="admin-businesses-container-head-buttons"
                >
                    <i className ="fa-solid fa-plus"></i> Create Business
                </button>
            </div>
            <ul>
                {Object.values(this.props.businesses).map((business:any, index) => this.businessCard(business, index))}
            </ul>
            {this.state.isLoading && 
                <div
                    className="admin-businesses-container-loader"
                >
                    <i className="fa-solid fa-rotate loading-spinner"></i>
                    <div>Fetching businesses...</div>
                </div>
            }
            {this.state.loadedAll && 
                <div className="admin-businesses-container-loader">Loaded all results</div>
            }
        </div>
    }
}

export default AdminBusinesses;