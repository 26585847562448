import { combineReducers } from "redux"
import businessReducer from "./business-reducer"
import businessTypesReducer from "./business-types-reducer"
import businessSearchReducer from "./business-search-reducer"

const businessRootReducer = combineReducers({
    businesses: businessReducer,
    businessTypes: businessTypesReducer,
    searchedBusinesses: businessSearchReducer
})

export default businessRootReducer;