import React from "react";
import { Link } from "react-router-dom";

class VetCardComponent extends React.Component{

    scroll: any = null;
    addressScroll: any = null;

    constructor(props:any){
        super(props);
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.handleAddressMouseEnter = this.handleAddressMouseEnter.bind(this);
        this.handleAddressMouseLeave = this.handleAddressMouseLeave.bind(this);
    }

    handleMouseEnter(e: any){
        clearInterval(this.scroll);
        this.scroll = setInterval(() => {
            e.target.scrollLeft += 1.5;
        }, 5)
    }

    handleMouseLeave(e: any){
        clearInterval(this.scroll);
        const targetElement = e.currentTarget;
        this.scroll = setInterval(() => {
            if (targetElement.scrollLeft <= 0) {
                clearInterval(this.scroll);
            } else {
                targetElement.scrollLeft -= 1.5;
            }
        }, 5);
    }

    handleAddressMouseEnter(e: any) {
        clearInterval(this.addressScroll);
        this.addressScroll = setInterval(() => {
            e.target.scrollLeft += 1;
        }, 5)
    }

    handleAddressMouseLeave(e: any) {
        clearInterval(this.addressScroll);
        const targetElement = e.currentTarget;
        this.addressScroll = setInterval(() => {
            if (targetElement.scrollLeft <= 0) {
                clearInterval(this.addressScroll);
            } else {
                targetElement.scrollLeft -= 1;
            }
        }, 5);
    }

    handleCareTypes(){
        return (<div className="vet-card-info-care-types-child">
                {this.props.vetCard.careTypes.slice(0,2).join(", ")}
            </div>);
    }

    render() {
        return (<div className="vet-card">
            <div className="vet-card-photo">
                <img src="https://web6q.lifelearn.com/longislandcityvc/wp-content/uploads/sites/827/2019/01/LIC-Logo-resized.png" alt="" />
            </div>
            <div className="vet-card-info">
                <div className="vet-card-info-name"
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                >
                    {this.props.vetCard.name}
                </div>
                <div className="vet-card-info-address"
                    onMouseEnter={this.handleAddressMouseEnter}
                    onMouseLeave={this.handleAddressMouseLeave}
                >
                    {this.props.vetCard.address}
                </div>
                <div className="vet-card-info-stars">
                    <i className="fa-solid fa-star"></i>{this.props.vetCard.stars.toFixed(1)}  ({this.props.vetCard.numReviews} Reviews)
                </div>
                <div className="vet-card-info-review">
                    <div className="vet-card-info-review-left">"</div>
                    <div className="vet-card-info-review-middle">
                        {this.props.vetCard.featuredReview.length < 180 ? this.props.vetCard.featuredReview : this.props.vetCard.featuredReview.slice(0,180) + "..."}
                    </div>
                    <div className="vet-card-info-review-right">"</div>
                </div>
                <div className="vet-card-info-care-types"> 
                    <i className="fa-solid fa-notes-medical vet-card-info-care-types-child"></i>
                    {this.handleCareTypes()}
                    {this.props.vetCard.careTypes.length > 2 ? 
                        <div className="vet-card-info-care-types-child additional">
                            +{this.props.vetCard.careTypes.length - 2}
                        </div> 
                        : ""}
                </div>
                <Link 
                    to={`/vet-profile/${this.props.vetCard.id}`}
                    className="vet-card-info-view-profile"
                >
                    View Profile
                </Link>
            </div>
        </div>)
    }
}

export default VetCardComponent;