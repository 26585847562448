import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import AnimalForm from "./animal-form";
import PhotoFormWithToast from "./photo-form";
import { updateUserProfilePhoto } from "../../actions/user_actions";

const mapStateToProps = (state: any, ownProps: any) => {
    return {

    };
}

const mapDispatchToProps = (dispatch: any) => ({
    updateUserProfilePhoto: (id: number, formData: any, showToast: Function) => dispatch(updateUserProfilePhoto(id, formData, showToast))
});

const PhotoFormContainer = withRouter(connect(mapStateToProps,mapDispatchToProps)(PhotoFormWithToast));
export default PhotoFormContainer;