import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReviewFormWithToast from "./review-form";
import { updateReview, createReview } from "../../actions/review-actions";

const mapStateToProps = (state: any, ownProps: any) => ({
    currentUser: state.entities.users[state.session.id]
});

const mapDispatchToProps = (dispatch: any) => ({
    updateReview: (review: any, showToast: Function) => dispatch(updateReview(review, showToast)),
    createReview: (review: any, showToast: Function) => dispatch(createReview(review, showToast))
});

const ReviewFormContainer = withRouter(connect(mapStateToProps,mapDispatchToProps)(ReviewFormWithToast));
export default ReviewFormContainer;