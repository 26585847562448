import React from "react";

class ClaimYourClinic extends React.Component{

    render(){
        return (<div className="claim-your-clinic-container">
            <div className="claim-your-clinic-container-banner">
                <div className="claim-your-clinic-container-banner-logo">
                    <div className="claim-your-clinic-container-banner-logo-circle">
                        <i className="fa-solid fa-hippo"></i>
                    </div>
                </div>
                <div className="claim-your-clinic-container-banner-contact">
                    <header>Contact us!</header>
                    <a href = "mailto: aprilsjourney@gmail.com">aprilsjourney@gmail.com</a>
                </div>
            </div>
            <div className="claim-your-clinic-container-list">
                <div className="claim-your-clinic-container-list-left">
                    <header>List your practice on April's Journey for free!</header>
                    <label>Claim or List your clinic to:</label>
                    <ul>
                        <li><i className="fa-solid fa-paw"></i> Book appointments</li>
                        <li><i className="fa-solid fa-paw"></i> Update your information</li>
                        <li><i className="fa-solid fa-paw"></i> Upload pictures</li>
                        <li><i className="fa-solid fa-paw"></i> List your staff</li>
                        <li><i className="fa-solid fa-paw"></i> And more!</li>
                    </ul>
                </div>
                <div className="claim-your-clinic-container-list-right">
                    <button>
                        Let's Get Started
                    </button>
                </div>
            </div>
        </div>)
    }
}

export default ClaimYourClinic