import React from 'react';
import { useLocation } from 'react-router-dom';
import { fetchCareTypes } from "../../../util/vet-api-util";
import { GoogleMap, LoadScript, Autocomplete, Marker, InfoWindow } from '@react-google-maps/api';
import queryString from 'query-string';
import VetSearchCard from './vet-search-card';
import { fetchTotalPageCountFromFilter } from '../../../util/business-api-util';

let autocomplete: any;

class VetSearchComponent extends React.Component {

    mapRef;
    times: any;

    constructor(props:any){
        super(props);
        const queryParams = new URLSearchParams(this.props.location.search);
        const lat = parseFloat(queryParams.get('lat')) ? parseFloat(queryParams.get('lat')) : 40.730610;
        const lng = parseFloat(queryParams.get('lng')) ? parseFloat(queryParams.get('lng')) : -73.935242;
        
        this.state = {
            careTypes: [],
            careType: queryParams.get('careType'),
            date: queryParams.get('date'),
            lat: lat,
            lng: lng,
            location: queryParams.get('location'),
            clickedMarkerVetId: null,
            mapCenter: { lat, lng },
            selectedVet: null,
            hoveredMarkerVetId: null,
            page: queryParams.get('page'),
            totalPageCount: null,
            userCity: "",
            selectedTime: null,
            selectedTimeDropdown: false,
            selectedAnimals: [],
            selectedAnimalsDropdown: false
        }

        const filter = {
            service: this.state.careType,
            date: this.state.date,
            lat: this.state.lat,
            lng: this.state.lng,
            page: this.state.page
        }

        // TODO: Narrow down the state to what the filter will be. 
        this.props.fetchVets(this.state);
        this.props.fetchBusinessesFromFilter(filter);
        this.props.fetchAnimalTypes();
        fetchTotalPageCountFromFilter(filter).then( (res: any) => {
            this.setState({totalPageCount: res.data.total_pages})
        })

        this.props.fetchServices();

        this.mapRef = React.createRef();
        this.times = this.generateTimeArray();

        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.renderMarkers = this.renderMarkers.bind(this);
        this.goToProfilePage = this.goToProfilePage.bind(this);
        this.timeOfDaySelector = this.timeOfDaySelector.bind(this);
        this.animalSelector = this.animalSelector.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
    }

    componentDidMount(): void {

        // Handle external clicks
        document.addEventListener('click', this.handleDocumentClick);

        //TODO: Make this into a maps service function. We are taking lat lng and returning a formatted city, state. 
        const geocoder = new window.google.maps.Geocoder();
        const latLng = new window.google.maps.LatLng(this.state.lat, this.state.lng);
        geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === "OK") {
              if (results[0]) {
                const addressComponents = results[0].address_components;
                let city = '';
                let state = '';

                // Iterate over address components to find city and state
                for (let i = 0; i < addressComponents.length; i++) {
                    const types = addressComponents[i].types;
                    if (types.includes("locality") || types.includes("sublocality")) {
                        city = addressComponents[i].long_name;
                    }
                    if (types.includes("administrative_area_level_1")) {
                        state = addressComponents[i].long_name;
                    }
                }

                const userCity: string = `${city}, ${state}`;
                this.setState({
                    userCity: userCity
                });
              }
            }
        });
    }

    componentWillUnmount() {
        // Clean up the event listener when the component is unmounted
        document.removeEventListener('click', this.handleDocumentClick);
    }

    handleDocumentClick = (event) => {
        // Check if the click occurred outside of the dropdowns
        const isInsideTimeDropdown = event.target.closest('.time-selector-container');
        const isInsideAnimalDropdown = event.target.closest('.animal-selector-container');
    
        if (!isInsideTimeDropdown) {
          // Clicked outside the time dropdown, so close it
          this.setState({ selectedTimeDropdown: false });
        }
    
        if (!isInsideAnimalDropdown) {
          // Clicked outside the animal dropdown, so close it
          this.setState({ selectedAnimalsDropdown: false });
        }
    };
    

    onLoad(autoC: any){
        autocomplete = autoC;
    }

    vetCard(business: any) {
        const isClickedMarker = business.id === this.state.clickedMarkerVetId;
        return (<VetSearchCard
            business={business}
            isClickedMarker={isClickedMarker}
            handleMouseEnter={() => this.setState({ hoveredMarkerVetId: business.id })}
            handleMouseLeave={() => this.setState({ hoveredMarkerVetId: null })}
            handleClick={() => this.handleMarkerClick(business)}
            goToProfilePage={(id: number) => this.goToProfilePage(id)}
            lat={this.state.lat}
            lng={this.state.lng}
        />)
    }

    generateTimeArray(){
        const times = [];
        const startTime = new Date().setHours(8, 0, 0); // Set start time to 8:00am
        const endTime = new Date().setHours(18, 0, 0); // Set end time to 6:00pm
      
        let currentTime = startTime;
        while (currentTime <= endTime) {
          const options = {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
          };
          const timeString = new Date(currentTime).toLocaleTimeString([], options);
          times.push(timeString);
          currentTime = new Date(currentTime).setMinutes(
            new Date(currentTime).getMinutes() + 15
          );
        }
      
        return times;
    };

    timeOfDaySelector(){
        return <div className='time-selector-container'>
            <div
                className='time-selector'
                onClick={() => {
                    this.setState({
                        selectedTimeDropdown: !this.state.selectedTimeDropdown,
                        selectedAnimalsDropdown: false
                    });
                }}
            >
                <i className="fa-regular fa-clock"></i>
                <div>
                    {this.state.selectedTime ? this.state.selectedTime : "Select Time"}
                </div>
            </div>
            <div 
                className={`time-selector-dropdown ${this.state.selectedTimeDropdown && 'active'}`}
            >
                <label
                    onClick={(e) => {
                        e.stopPropagation();
                        this.setState({selectedTime: "Any Time", selectedTimeDropdown: false});
                    }}
                >
                    Any Time
                </label>
                {this.times.map((time: any) => (<label
                    onClick={(e) => {
                        e.stopPropagation();
                        this.setState({selectedTime: time, selectedTimeDropdown: false});
                    }}
                >
                        {time}
                </label>))}
            </div>
        </div>
    }

    handleCheckbox(e){
        let animals = [...this.state.selectedAnimals];
        animals = animals.filter( animals => animals !== parseInt(e.target.value));
        if (animals.length === this.state.selectedAnimals.length){
            animals.push(parseInt(e.target.value));
        } 
        this.setState({selectedAnimals: animals});
    }

    animalSelector(){
        return <div className='animal-selector-container'>
            <div
                className="animal-selector"
                onClick={() => {
                    this.setState({
                        selectedAnimalsDropdown: !this.state.selectedAnimalsDropdown,
                        selectedTimeDropdown: false
                    })
                }}
            >
                <i className="fa-solid fa-paw"></i>
                <div>
                    {this.state.selectedAnimals.length > 0 ? `${this.state.selectedAnimals.length} Animals Selected` : "Select Animal(s)"}
                </div>
            </div>
            <div
                className={`animal-selector-dropdown ${this.state.selectedAnimalsDropdown && 'active'}`}
            >
                {Object.values(this.props.animals).map((animal: any) => <label
                        onClick={(e) => e.stopPropagation()}
                    >
                        <input 
                            type="checkbox"
                            value={animal.id}
                            onClick={this.handleCheckbox}
                            checked={this.state.selectedAnimals.includes(animal.id)}
                        />
                        <div className="animal-name"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <i className={animal.icon}></i>
                            {animal.animal_name}
                        </div>
                    </label>)}
            </div>
        </div>
    }

    handleSubmit(e: any){
        e.preventDefault();
        const { location, careType, date, lat, lng, page } = this.state;
        const searchParams = {
            location,
            careType,
            date,
            lat,
            lng,
            page
        };
        searchParams.page = 1;
        const queryStringParams = queryString.stringify(searchParams);
        const route = `/vetsearch?${queryStringParams}`;
        this.props.history.push(route);
    }

    handleInput(type: any){
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.currentTarget.value;
            debugger;
            if (type === 'location') {
                this.setState({ location: value });
            } else {
                this.setState({ [type]: value });
            }
        };
    }

    handleMarkerClick(business: any) {
        // Assuming there is a unique identifier for each vet, such as an ID
        const businessId = business.id; // Replace 'id' with the actual property name
        this.setState({selectedVet: business})
    
        // Scroll to the corresponding vet clinic
        const element = document.getElementById(`vet-${businessId}`);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
            this.setState({ clickedMarkerVetId: businessId });
            setTimeout(() => {
                this.setState({ clickedMarkerVetId: null });
            }, 1500); // Adjust the delay as needed
        }
    }

    renderMarkers() {
        const markerOptions = {
            fillColor: '#FF0000', // Set the background color of the marker
            fillOpacity: 1, // Adjust the opacity of the marker background
            strokeColor: '#000000', // Set the border color of the marker
            strokeWeight: 1, // Adjust the thickness of the marker border
          };

        return Object.values(this.props.searchedBusinesses).map((business) => {
            const isHoveredMarker = business.id === this.state.hoveredMarkerVetId;
            const isClickedMarker = business.id === this.state.selectedVet?.id;
            var pinColor = "#FDEEC4";
            var pinLabel = "A";

            // Pick your pin (hole or no hole)
            var pinSVGHole = "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z";
            var labelOriginHole = new google.maps.Point(12,15);
            var pinSVGFilled = "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z";
            var labelOriginFilled =  new google.maps.Point(12,9);


            var markerImage = {  // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerLabel
                path: pinSVGFilled,
                anchor: new google.maps.Point(12,17),
                fillOpacity: 1,
                fillColor: isHoveredMarker ? '#163C22' : pinColor,
                strokeWeight: 2,
                strokeColor: "#163C22",
                scale: 2,
                labelOrigin: labelOriginFilled,
            };

            return (this.state.selectedVet?.id !== business.id ? <Marker
                className="marker"
                key={business.number}
                position={{ lat: business.lat, lng: business.lng }}
                label={{
                    text: (business.number).toString(),
                    color: isHoveredMarker ? pinColor : '#163C22', // Set the text color of the label
                    fontSize: '14px', // Set the font size of the label
                    fontWeight: 'bold', // Set the font weight of the label
                    fontFamily: 'Gill Sans',
                }}
                icon={markerImage}
                onClick={(e) => {
                    e.domEvent.preventDefault();
                    if (!isClickedMarker) {
                        this.handleMarkerClick(business);
                    }
                }}
                optimized={false} 
                zIndex={isHoveredMarker ? 100 : business.number}
                onMouseOver={() => this.setState({ hoveredMarkerVetId: business.id })}
                onMouseOut={() => this.setState({ hoveredMarkerVetId: null })}
            >
            </Marker> : ""
            )}
        );
    }

    goToProfilePage(id: number){
        this.props.history.push(`/vet-profile/${id}`)
    }
    
    goToPage(pageNum: number){
        const queryParams = new URLSearchParams(this.props.location.search);
        const { location, careType, date, lat, lng, page } = this.state;
        const searchParams = {
            location,
            careType,
            date,
            lat,
            lng,
            page
        };
        searchParams.page = pageNum;
        const queryStringParams = queryString.stringify(searchParams);
        const route = `/vetsearch?${queryStringParams}`;
        this.props.history.push(route);
    }

    render(){
        const { lat, lng, mapCenter } = this.state;
        const containerStyle = {
            width: '100%',
            height: '90vh',
            cursor: 'default'
        };

        return (<div className='vet-search-container'>
            <div className='vet-search-container-left'>
                <form onSubmit={this.handleSubmit}>
                    <div className="vet-search-container-left-search-options">
                        <select className="vet-search-container-left-search-options-select"
                            onChange={this.handleInput("careType")}
                            value={this.state.careType}
                        >
                            <option value="" selected>Any Type of Care</option>
                            {Object.values(this.props.services).map((careType: any) => (
                                <option value={careType.name}>
                                    {careType.name}
                                </option>
                            ))}
                        </select>
                        <Autocomplete
                            onLoad={this.onLoad}
                            onPlaceChanged={() => {
                                const place = autocomplete.getPlace();
                                this.setState({ 
                                    location: place.formatted_address,
                                    lat: place.geometry.location.lat(),
                                    lng: place.geometry.location.lng(),
                                });
                            }}
                            className="vet-search-container-left-search-options-area"
                        >
                            <input
                            type="text"
                            placeholder="City, State, or Zip Code"
                            value={this.state.location}
                            onChange={this.handleInput("location")}
                            />
                        </Autocomplete>
                        <input 
                            className="vet-search-container-left-search-options-date" 
                            type="date" 
                            onChange={this.handleInput("date")}
                            value={this.state.date}
                        />
                        <button type="submit"><i className="fa fa-search"></i></button>
                    </div>
                </form>
                <div className='vet-search-container-left-filters' >
                    <div className='additional-filters'>
                        {this.timeOfDaySelector()}
                        {this.animalSelector()}
                    </div>
                    <header>
                        Best <span>{this.state.careType ? this.state.careType : "Businesses"}</span> in <span>{this.state.userCity}</span>
                    </header>
                    <div className='sort-by'>
                        <div>Sort by: </div>
                        <select name="" id="">
                            <option value="recommended" defaultChecked >Recommended</option>
                            <option value="distance">Closest</option>
                            <option value="reviewCount">Review Count</option>
                            <option value="reviews">Rating</option>
                        </select>
                        <div className="tooltip">
                            <i className="fa-solid fa-question"></i>
                            <span className="tooltiptext">Additional information for sorting options goes here.</span>
                        </div>
                    </div>
                </div>
                <div className='vet-search-container-left-vets'>
                    {Object.values(this.props.searchedBusinesses).map(business => this.vetCard(business))}
                </div>
                <div className='vet-search-container-left-pages' >
                    {this.state.page > 1 ? <div
                        className='vet-search-container-left-pages-button'
                        onClick={() => this.goToPage(1)}
                        ><i className="fa-solid fa-angles-left"></i>
                    </div> : null}
                    {this.state.page > 1 ? <div
                        className='vet-search-container-left-pages-button'
                        onClick={() => this.goToPage(parseInt(this.state.page) - 1)}
                        ><i className="fa-solid fa-angle-left"></i>
                    </div> : null}
                    <div className='vet-search-container-left-pages-middle'>
                        Page {this.state.page} of {this.state.totalPageCount}
                    </div>
                    {this.state.page < this.state.totalPageCount ? <div
                        className='vet-search-container-left-pages-button'
                        onClick={() => this.goToPage(parseInt(this.state.page) + 1)}
                        ><i className="fa-solid fa-angle-right"></i>
                    </div> : null}
                    {this.state.page < this.state.totalPageCount ? <div
                        className='vet-search-container-left-pages-button'
                        onClick={() => this.goToPage(this.state.totalPageCount)}
                        ><i className="fa-solid fa-angles-right"></i>
                    </div> : null}
                </div>

            </div>
            <div className='vet-search-container-right'>
                <GoogleMap
                    ref={this.mapRef} 
                    mapContainerStyle={containerStyle}
                    center={mapCenter}
                    zoom={12}
                    clickableIcons={false}
                    className="map-container"
                    options={{
                        mapTypeControl: false
                    }}
                    
                >
                    {this.renderMarkers()}
                    {this.state.selectedVet ? <InfoWindow 
                        position={{lat: this.state.selectedVet.lat, lng: this.state.selectedVet.lng}} 
                        onCloseClick={() => this.setState({selectedVet: null})}>
                        <div className="vet-info-window">
                            <div className='vet-info-window-name'>
                                {this.state.selectedVet.name}
                            </div>
                        </div>
                    </InfoWindow> : null}
                </GoogleMap>
            </div>
        </div>)
    }
}

export default VetSearchComponent;

