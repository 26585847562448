import React from "react";
import { ToastConsumer, ToastProvider } from '../../shared/toast.service';
import { ToastType } from "../../shared/toast.service";
import ToastService from '../../shared/toast.service';

class ServiceForm extends React.Component {

    constructor(props: any){
        super(props);

        this.state = {
            title: this.props.service ? "Edit Service" : "Create Service",
            name: this.props.service ? this.props.service.name : "",
            formType: this.props.service ? "Update Service" : "Create Service",
            requestDelete: false,
            delete: ""
        }

        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInput(type: any){
        return (e) => {
            this.setState({ [type]: e.currentTarget.value})
        }
    }

    handleSubmit(e: any){
        e.preventDefault();
        let service: any = {service: {}};

        if (this.props.service){
            service.service.id = this.props.service.id;
            service.service.name = this.state.name;
            // Conditionally determine whether or not update or create action is used based on if props.id exists
            this.props.updateService(service, this.props.showToast);
        } else {
            service.service.name = this.state.name;
            this.props.createService(service, this.props.showToast);
        }
    }

    render(){
        return <div className='modal-container-animal-form'>
        <header>{this.state.title}</header>
        <form onSubmit={this.handleSubmit}
            className="modal-container-animal-form"
        >
            <label>
                <div>Name</div>
                <input type="text" 
                    value={this.state.name}
                    onChange={this.handleInput("name")}
                />
            </label>
            <div className='icon-container'>
                <div className='modal-container-animal-form-icon'>
                    <label>{this.state.name}</label>
                </div>
            </div>
            <div className='buttons'>
                <input 
                    type="submit"
                    value={this.state.formType}
                    className="submit"
                />
                {this.props.service ? <button
                    onClick={(e) => {
                        e.preventDefault();
                        this.setState({requestDelete: true});
                    }}
                >
                    Delete Service
                </button> : ""}
                {this.state.requestDelete && <div className="request-delete">
                    <label htmlFor="">Write "DELETE" to confirm:</label>
                    <input type="text" 
                        onChange={this.handleInput("delete")}
                    />
                    <div className="confirm">
                        <button className="confirm-delete"
                            onClick={(e) => {
                                e.preventDefault();
                                if (this.state.delete === "DELETE"){
                                    this.props.deleteService(this.props.service.id, this.props.showToast);
                                } else {
                                    this.props.showToast(ToastType.Error,"Please write DELETE to delete.");
                                }
                            }}
                        >
                            Confirm Delete
                        </button>
                        <button className="confirm-cancel"
                            onClick={() => this.setState({requestDelete: false, delete: ""})}
                        >Cancel Delete</button>
                    </div>
                </div>}

            </div>
        </form>
    </div>
    }
}

const ServiceFormWithToast = (props: any) => (
    <ToastConsumer>
    {(showToast) => <ServiceForm showToast={showToast} {...props} />}
    </ToastConsumer>
);

export default ServiceFormWithToast;