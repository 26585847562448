import React from 'react';
import { ToastConsumer, ToastProvider } from '../../shared/toast.service';
import ToastService from '../../shared/toast.service';
import axios from 'axios';

class PhotoForm extends React.Component{

    constructor(props: any){
        super(props);

        this.state = {
            photoFile: null,
            photoUrl: this.props.user?.photoUrl
        };
        
        debugger;
        console.log(this.state);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFile = this.handleFile.bind(this);
    }

    handleInput(type: any){
        console.log(type);
        return (e) => {
            this.setState({ [type]: e.currentTarget.value})
        }
    }

    handleFile(e: any){
        const file = e.currentTarget.files[0];
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
            this.setState({photoFile: file, photoUrl: fileReader.result})
        }
        if (file) {
            fileReader.readAsDataURL(file);
        }
    }

    handleSubmit(e: any){
        e.preventDefault();
        const formData = new FormData();
        formData.append('user[id]', this.props.user.id)
        formData.append('user[profile_photo]', this.state.photoFile);

        this.props.updateUserProfilePhoto(this.props.user.id, formData,this.props.showToast);
    }

    render() {
        return <div className='modal-container-animal-form'>
            <header>Update Profile Photo</header>
            <form onSubmit={this.handleSubmit}>
                {/* <label>
                    <div>Name</div>
                    <input type="text" 
                        // value={this.state.animal_name}
                        onChange={this.handleInput("animal_name")}
                    />
                </label> */}
                <label>
                    <div>Add Photo</div>
                    <input type="file" 
                        onChange={this.handleFile}
                    />
                </label>
                <div className='icon-container'>
                    <img src={this.state.photoUrl} alt="" />
                </div>
                <div className='buttons'>
                    <input 
                        type="submit"
                        // value={this.state.formType}
                        className="submit"
                    />
                    {this.props.animal ? <button>
                        Delete Animal
                    </button> : ""}
                    
                </div>
            </form>
        </div>
    }
}

const PhotoFormWithToast = (props: any) => (
        <ToastConsumer>
        {(showToast) => <PhotoForm showToast={showToast} {...props} />}
        </ToastConsumer>
);

export default PhotoFormWithToast;