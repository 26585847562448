import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import UserProfile from "./user-profile";
import { fetchUserFromUsername } from "../../../actions/user_actions";
import { showPhotoForm, showConfirmationForm } from "../../../actions/ui-actions";


const mapStateToProps = (state: any, ownProps: any) => ({
    username: ownProps.match.params.username,
    user: Object.values(state.entities.users).filter((user:any) => user.username === ownProps.match.params.username)[0],
    currentUser: state.entities.users[state.session.id]
})

const mapDispatchToProps = (dispatch: any) => ({
    fetchUserFromUsername: (username: string) => dispatch(fetchUserFromUsername(username)),
    showPhotoForm: (user: any) => dispatch(showPhotoForm(user)),
    showConfirmationForm: (message, onSuccess) => dispatch(showConfirmationForm(message, onSuccess))
});

const UserProfileContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(UserProfile));
export default UserProfileContainer;