import React from "react";
import { GoogleMap, LoadScript, Autocomplete, Marker, InfoWindow } from '@react-google-maps/api';
import { fetchReview } from "../../../util/review-api-util";
import { isBusinessBookmarked, toggleUserBookmark } from "../../../util/bookmark-api-util";
import axios from 'axios';
import { ToastConsumer, ToastProvider } from '../../../shared/toast.service';
import { ToastType } from "../../../shared/toast.service";
import ToastService from '../../../shared/toast.service';

class VetProfile extends React.Component {

    reviewsRef: any;
    appointmentsRef: any;
    aboutRef: any;

    constructor(props: any){
        super(props);

        const date = new Date();
        const dateString = this.getDate(date);

        this.state = {
            id: null,
            loading: true,
            notFound: false,
            name: "Awesome Veterinary Clinic",
            businessType: "Animal Hospital",
            services: [],
            address: "1101 Tower Drive, Edgewater, NJ, 07020",
            userReview: null,
            rating: 4.2,
            reviewCount: 104,
            reviewBreakdown: {
                5: 90,
                4: 8,
                3: 3,
                2: 3,
                1: 0
            },
            lat: 40.743635,
            lng: -73.953818,
            website: "https://www.awesomevetclinic.com",
            email: "info@awesomevetclinic.com",
            phoneNumber: "7184567897",
            claimed: true,
            selectedPet: null,
            selectedTime: null,
            date: dateString,
            googleReviews: null,
            placeId: null,
            bookmarked: false,
            description: "One of New York’s Oldest and Most Respected Veterinary Practices New York City Veterinary Group started out at East Side Animal Hospital when it was founded in 1948 and is one of the oldest veterinary animal hospitals in New York City. Since its earliest days in Midtown Manhattan, we have proven ourselves to be thorough and compassionate. Through your pet, we have become part of your life and part of your neighborhood. Now with 2 locations in Long Island City, we have become part of that neighborhood as well. Our veterinarians in Midtown and Long Island City provide the best care a pet can get.",
            imageUrl: "https://edgewateranimalhospital.com/wp-content/uploads/2022/08/Edgewater-AH-FINAL.png",
            staff: [
                {
                    id: 1,
                    name: "April Kim, DVM",
                    title: "Veterinarian",
                    profileImg: "https://www.thesprucepets.com/thmb/U0kyV97nuth1zTJoRovNAcLV8YM=/2121x0/filters:no_upscale():strip_icc()/AmyLanePhotography-b0b91f2200394ba688e7271ad675176c.jpg"
                },
                {
                    id: 2,
                    name: "David Kim, DVM",
                    title: "Veterinarian",
                    profileImg: "https://c8.alamy.com/comp/H0GK43/portrait-of-male-vet-H0GK43.jpg"
                },
                {
                    id: 3,
                    name: "Jamie Kim",
                    title: "Veterinary Technician",
                    profileImg: "https://st.depositphotos.com/1011643/2272/i/450/depositphotos_22723005-veterinary-nurse-holding-dog.jpg"
                },
                {
                    id: 4,
                    name: "Abigail Kim",
                    title: "Veterinary Technician",
                    profileImg: "https://i.cbc.ca/1.5359228.1577206958!/fileImage/httpImage/image.jpg_gen/derivatives/16x9_620/smudge-the-viral-cat.jpg"
                }
            ]
        }

        this.reviewsRef = React.createRef();
        this.appointmentsRef = React.createRef();
        this.aboutRef = React.createRef();

        this.staffCards=this.staffCards.bind(this);
        this.renderMapAndInfo = this.renderMapAndInfo.bind(this);
        this.bookAppointment = this.bookAppointment.bind(this);
        this.setUpMapComponent = this.setUpMapComponent.bind(this);
        this.setNotFound = this.setNotFound.bind(this);
        this.reviews = this.reviews.bind(this);
        this.scrollTo = this.scrollTo.bind(this);
        this.setBusinessState = this.setBusinessState.bind(this);
    }

    scrollTo(e: any, ref: any){
        e.preventDefault();
        if (ref){
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{}>, snapshot?: any): void {
        debugger;
        if (JSON.stringify(prevProps.business) !== JSON.stringify(this.props.business)){

            this.setBusinessState();
            const {id, place_id} = this.props.business;

            if (this.props.currentUser?.id){
                this.props.fetchUserReview(this.props.currentUser?.id, id);
            }

            this.setUpMapComponent(place_id);
        }

        if (JSON.stringify(prevProps.reviews) !== JSON.stringify(this.props.reviews)){
            this.props.fetchBusiness(this.props.vetId);
            const userReview = Object.values(this.props.reviews).filter(review => {
                return review.user_id === this.props.currentUser?.id && review.business_id === this.state.id
            })

            if (userReview.length > 0){
                this.setState({userReview: userReview[0]});
            } else {
                this.setState({userReview: null});
            }
        }
    }

    setNotFound(){
        this.setState({notFound: true});
    }

    setUpMapComponent(placeId: number){
        const map = new google.maps.Map(document.getElementById('map'));
        const service = new google.maps.places.PlacesService(map);
        service.getDetails({placeId: placeId}, (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                this.setState({googleReviews: place});
            }
        });
    }

    setBusinessState(){
        const {name, business_type, claimed, 
            description, lat, lng, image_url,
            phone, services, website, email,
            id, place_id
        } = this.props.business;
        
        const businessType = business_type;
        const imageUrl = image_url;
        const placeId = place_id;
        const reviewBreakdown = this.props.business.average_reviews.percentages;
        const reviewCount = this.props.business.review_count;
        const rating = this.props.business?.average_reviews?.averages?.recommend;

        this.setState(prevState => ({
            ...prevState,
            name,
            businessType,
            claimed,
            description,
            lat,
            lng,
            imageUrl,
            phone,
            services, 
            website, 
            email,
            id,
            placeId,
            rating,
            reviewCount,
            reviewBreakdown
        }));

        isBusinessBookmarked(id).then(res => {
            this.setState({bookmarked: res.data[0]})
        });
    }

    componentDidMount(): void {

        // If there already is a business in the state, assign the state accordingly
        if (this.props.business){
            this.setBusinessState();
        }

        const businessProfileFunctions = {
            setNotFound: this.setNotFound
        }

        this.props.fetchBusiness(this.props.vetId, businessProfileFunctions);
        if (this.props.currentUser?.id){
            this.props.fetchUserReview(this.props.currentUser?.id, this.props.vetId);
        }

        setTimeout(() => {
            this.setState({loading: false})
        }, 1000)
    }

    getDate(today: Date){
        return today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
    }

    staffCards(){
        if (this.state.staff.length > 0 ){
            return <div className="vet-profile-container-staff">
                <header>Meet the Team</header>
                <div className="vet-profile-container-staff-cards" >
                    {this.state.staff.map(person => (<div className="vet-profile-container-staff-card">
                        <div className="vet-profile-container-staff-card-image">
                            {person.profileImg?.length > 0 ? <img src={person.profileImg}></img> :  <i className="fa-solid fa-user"></i>}
                        </div>
                        <div className="vet-profile-container-staff-card-info">
                            <label className="bold">{person.name}</label>
                            <label className="italics">{person.title}</label>
                        </div>
                    </div>))}
                </div>
            </div>
        }
    }

    renderMapAndInfo(){
        const { lat, lng} = this.state;
        const mapCenter = {lat, lng};
        const containerStyle = {
            width: '100%',
            height: '100vh',
            cursor: 'default'
        };

        const infoWindowOptions = {
            disableAutoPan: true, // Disable automatic panning of the InfoWindow
            closeBox: false, // Remove the close button from the InfoWindow
            clickable: false, // Disable click events on the InfoWindow
            draggable: false, // Disable dragging of the InfoWindow
        };

        return <div 
            ref={this.aboutRef}
            className="vet-profile-container-map"
        >
            <div className="vet-profile-container-map-left">
                <header>Care Offered <i className="fa-solid fa-briefcase-medical"></i></header>
                <div className="vet-profile-container-map-left-care-types">{this.state.services.map(service => service.name).join(" • ")}</div>
                <label>About {this.state.name}</label>
                <ul>
                    <li><i className="fa-regular fa-calendar-check"></i>Fast booking available</li>
                    <li><i className="fa-solid fa-ranking-star"></i>90% of reviewers gave 5 stars</li>
                    <li><i className="fa-solid fa-earth-americas"></i>Multilingual Staff</li>
                    <li><i className="fa-solid fa-heart-circle-bolt"></i>24/7 Urgent Care</li>
                </ul>
                <body>
                    {this.state.description}
                </body>
            </div>
            <div className="vet-profile-container-map-right">
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={mapCenter}
                    zoom={16}
                    options={{
                        mapTypeControl: false
                    }}
                >
                    <InfoWindow
                        position={{lat: this.state.lat, lng: this.state.lng}}
                        options={infoWindowOptions}
                    >
                        <div className="vet-profile-container-map-right-info">
                            {this.state.name}
                        </div>
                    </InfoWindow>
                </GoogleMap>
            </div>
        </div>
    }

    generateTimeArray(){
        const times = [];
        const startTime = new Date().setHours(8, 0, 0); // Set start time to 8:00am
        const endTime = new Date().setHours(18, 0, 0); // Set end time to 6:00pm
      
        let currentTime = startTime;
        while (currentTime <= endTime) {
          const options = {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
          };
          const timeString = new Date(currentTime).toLocaleTimeString([], options);
          times.push(timeString);
          currentTime = new Date(currentTime).setMinutes(
            new Date(currentTime).getMinutes() + 15
          );
        }
      
        return times;
    };

    handleInput(type: any){
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.currentTarget.value;
            if (type === 'location') {
                this.setState({ location: value });
            } else {
                this.setState({ [type]: value });
            }
        };
    }

    bookAppointment(){
        if (!this.state.claimed){
            return null;
        }

        const options = { 
            month: '2-digit', 
            day: '2-digit', 
            year: 'numeric' 
          };

        return <div 
            ref={this.appointmentsRef}
            className="vet-profile-container-book"
        >
            <div className="vet-profile-container-book-left">
                <header>Book an Appointment for Free</header>
                <form>
                    <select name="" id="petSelect"
                        onChange={this.handleInput("selectedPet")}
                        value={this.state.selectedPet}
                    >
                        <option value="" disabled selected>Select Pet</option>
                        <option value="fido">Fido</option>
                    </select>
                    <input type="date" 
                        onChange={this.handleInput("date")}
                        value={this.state.date}
                        min={this.state.date}
                    />
                    <select name="" id="timeSelect">
                        <option value="" selected>All Day</option>
                    </select>
                </form>
                <div className="vet-profile-container-book-left-available">Available Times</div>
                <div className="vet-profile-container-book-left-times">
                    {this.generateTimeArray().map(time => (<div
                        className={`vet-profile-container-book-left-times-time ${time.toString() === this.state.selectedTime ? 'selected' : ""}`}
                        onClick={() => {
                            this.setState({selectedTime: time.toString()});
                            console.log(this.state);
                        }}
                    >
                        {time.toString()}
                    </div>))}
                </div>
                <div className="vet-profile-container-book-left-additional-info">
                    <label>Need to Know</label>
                    <body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </body>
                </div>
            </div>
            <div className="vet-profile-container-book-right">
                <header>About Your Appointment</header>
                <ul>
                    <li>
                        <label>Pet:</label>
                        <div>
                            {this.state.selectedPet}
                        </div>
                    </li>
                    <li>
                        <label>Date:</label>
                        <div>
                            {new Date(this.state.date).toLocaleDateString([], options)}
                        </div>
                    </li>
                    <li>
                        <label>Time:</label>
                        <div>{this.state.selectedTime}</div>
                    </li>
                    <li>
                        <label>New Patient:</label>
                        <div>
                            <label>
                                <input type="radio" name="patientType" value="true" />
                                New Patient
                            </label>
                            <label>
                                <input type="radio" name="patientType" value="false" />
                                Returning Patient
                            </label>
                        </div>
                    </li>
                </ul>

                <div className="additional-request">Additional Request or Notes for Appointment</div>
                <textarea className="additional-request-body"/>
                <button>
                    Book Appointment!
                </button>
            </div>
        </div>
    }

    reviews(){
        return <div 
            id="business-reviews"
            ref={this.reviewsRef}
            className="vet-profile-container-reviews">
            <div className="vet-profile-container-reviews-left">
                <header>Reviews</header>
                <div className="vet-profile-container-reviews-left-rating">{this.state.rating}</div>
                <div className="vet-profile-container-reviews-left-rate">
                    <div className="yellow" style={{width: `${(this.state.rating/5) * 150}px`}}>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                    </div>
                    <div className="gray">
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                    </div>
                </div>
                <div className="vet-profile-container-reviews-left-review-count">
                    {this.state.reviewCount} Reviews
                </div>
                <div className="vet-profile-container-reviews-left-breakdown">
                    {Object.keys(this.state.reviewBreakdown).reverse().map(starRating => (<div className="vet-profile-container-reviews-left-breakdown-row">
                        <div className="breakdown-left">
                            {starRating}<i className="fa-solid fa-star"></i>
                        </div>
                        <div className="breakdown-bar">
                            <div className="breakdown-bar-fill" style={{width: `${200*(this.state.reviewBreakdown[starRating]*1.0/100)}px`}}>
                            </div>
                        </div>
                        <div className="percent">
                            {this.state.reviewBreakdown[starRating]}%
                        </div>
                    </div>))}
                </div>
                <div className="vet-profile-container-reviews-left-detail-rating">
                    <label>Bedside Manner</label>
                    <div className="vet-profile-container-reviews-left-rate">
                        <div className="yellow" style={{width: `${(this.state.rating/5) * 150}px`}}>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                        </div>
                        <div className="gray">
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                        </div>
                    </div>
                    <label>Average Wait Time</label>
                    <div className="vet-profile-container-reviews-left-rate">
                        <div className="yellow" style={{width: `${(this.state.rating/5) * 150}px`}}>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                        </div>
                        <div className="gray">
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                        </div>
                    </div>
                    <label>Staff Friendliness</label>
                    <div className="vet-profile-container-reviews-left-rate">
                        <div className="yellow" style={{width: `${(this.state.rating/5) * 150}px`}}>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                        </div>
                        <div className="gray">
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                        </div>
                    </div>
                </div>
                {this.props.currentUser ? 
                    <button
                        onClick={() => {
                            if (this.state.userReview){
                                const review = Object.assign({}, this.state.userReview);
                                review.businessName = this.state.name;
                                this.props.showReviewModal(review);
                            } else {
                                const review = {
                                    business_id: this.props.business.id,
                                    businessName: this.state.name
                                }
                                this.props.showReviewModal(review);
                            }
                        }}
                    >
                        {this.state.userReview ? "Update Review" : "Submit Review"}
                    </button> :
                    <button
                        onClick={() => {
                            this.props.history.push("/user-login")
                        }}
                    >
                        Login to Leave a Review
                    </button>
                }
            </div>
            <div className="vet-profile-container-reviews-right">
                <div className="vet-profile-container-reviews-right-reviews">
                    {this.state.googleReviews?.reviews?.map(review => (<div 
                        className="vet-profile-container-reviews-right-reviews-google"
                    >
                        <label className="vet-profile-container-reviews-right-reviews-google-label">
                            <div className="left">
                                <img src={review.profile_photo_url} alt="" />
                                <div className="name">
                                    <label>
                                        {review.author_name} 
                                        <img 
                                            className="fa-google"
                                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/2008px-Google_%22G%22_Logo.svg.png" alt="" /> 
                                    </label>
                                    <div className="vet-profile-container-reviews-right-reviews-google-stars">
                                        <div className="vet-profile-container-reviews-right-reviews-google-stars-overlay">
                                            <div className="yellow" style={{width: `${(review.rating/5) * 120}px`}}>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                            </div>
                                            <div className="gray">
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="right"> 
                                {review.relative_time_description}
                            </div>
                        </label>
                        
                        <body>
                            {review.text}
                        </body>
                    </div>))}
                </div>
            </div>
        </div>
    }

    formatPhoneNumber(phoneNumber: string): string {
        const digitsOnly = phoneNumber.replace(/\D/g, '');
        const areaCode = digitsOnly.slice(0, 3);
        const firstPart = digitsOnly.slice(3, 6);
        const secondPart = digitsOnly.slice(6);
        const formattedPhoneNumber = `(${areaCode})-${firstPart}-${secondPart}`;
        return formattedPhoneNumber;
      }

    render(){
        if (this.state.loading){
            return <div className="vet-profile-container">
                <div className="loading">
                    <i className="fa-solid fa-rotate loading-spinner"></i>
                    <div>Fetching business...</div>
                    <div id="map"></div>
                </div>
            </div>
        }

        if (this.state.notFound){
            return <div className="vet-profile-container">
                <div className="not-found">
                    <div className="user-profile-restricted-icon">
                        <i className="fa-solid fa-building-lock"></i>
                    </div>
                    <div>
                        Business not found.
                    </div>
                </div>
            </div>
        }
        return <div className="vet-profile-container">
            <div className="vet-profile-container-top-links">
                <div className="vet-profile-container-top-links-left">
                    <button
                        onClick={() => this.props.history.goBack()}
                    >
                        <i className="fa-solid fa-circle-left"></i>
                        Back
                    </button>
                </div>
                <div className="vet-profile-container-top-links-right">
                    <button
                        
                        onClick={(e) => this.scrollTo(e, this.aboutRef)}
                    >
                        About
                    </button>
                    <button
                        className="last-right-button"
                        onClick={(e) => this.scrollTo(e, this.appointmentsRef)}
                    >
                        Book Appointments
                    </button>
                    <button
                        className="last-right-button"
                        onClick={(e) => this.scrollTo(e, this.reviewsRef)}
                    >
                        Reviews
                    </button>
                    {this.props.currentUser?.is_admin ? 
                        <button
                            className="last-right-button admin-button"
                            onClick={()=> this.props.showBusinessModal(this.props.business)}
                        >
                            <i className="fa-solid fa-pen-to-square"></i>
                            Edit Business
                        </button> : ""}
                </div>
            </div>
            <div className="vet-profile-container-banner">
                <div className="vet-profile-container-banner-left">
                    <div className="vet-profile-container-banner-image">
                        {this.state.imageUrl ? <img src={this.state.imageUrl}/> : <i className="fa-solid fa-hospital"></i>}
                    </div>
                    <div className="vet-profile-container-banner-info">
                        <header>{this.state.name}{this.state.claimed ? <i className="fa-solid fa-circle-check"></i> : ""}</header>
                        <div className='vet-profile-container-banner-info-type'><i className="fa-solid fa-hospital"></i>{this.state.businessType.business_type}</div>
                        <div className='vet-profile-container-banner-info-address'>
                            <i className="fa-solid fa-location-dot"></i>
                            {this.state.address}
                        </div>
                        <div className='vet-profile-container-banner-info-rating'>
                            <i className="fa-solid fa-star"></i>{this.state.rating}  ({this.state.reviewCount} Reviews)
                        </div>
                        <div className='vet-profile-container-banner-info-phone'>
                            <i className="fa-solid fa-phone"></i>
                            <a href={`tel:+${this.state.phoneNumber}`}>{this.formatPhoneNumber(this.state.phoneNumber.toString())}</a>
                        </div>
                        <div className='vet-profile-container-banner-info-website'>
                            <i className="fa-solid fa-globe"></i>
                            <a href={this.state.website} target="_blank">{this.state.website}</a>
                        </div>
                    </div>
                </div>
                <div className="vet-profile-container-banner-links">

                    <i className={`fa-solid fa-bookmark ${this.state.bookmarked && 'active'}`}
                        onClick={() => {
                            if (this.props.currentUser){
                                const ids = {
                                    user_id: this.props.currentUser.id,
                                    business_id: this.state.id
                                }
                                toggleUserBookmark({bookmark: ids}).then(res => {
                                    const toastMessage = !!res.data.id ? "Business bookmarked." : "Bookmark removed."; 
                                    this.setState({bookmarked: !!res.data?.id})
                                    this.props.showToast(ToastType.Success, toastMessage);
                                })
                            } else {
                                this.props.history.push('/user-login');
                            }
                        }}
                    ></i>
                </div>
            </div>
            {this.staffCards()}
            {this.renderMapAndInfo()}
            {this.bookAppointment()}
            <div id="map"></div>
            {this.reviews()}
        </div>
    }
}

// export default VetProfile;

const VetProfileWithToast = (props: any) => (
    <ToastConsumer>
    {(showToast) => <VetProfile showToast={showToast} {...props} />}
    </ToastConsumer>
);

export default VetProfileWithToast