import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AdminBusinesses from "./admin-businesses";
import { showBusinessModal } from "../../../../actions/ui-actions";
import { fetchBusinesses, clearAllBusinesses } from "../../../../actions/business-actions";

const mapStateToProps = (state: any, ownProps: any) => ({
    businesses: state.entities.businessRoot.businesses
})

const mapDispatchToProps = (dispatch: any) => ({
    clearAllBusinesses: () => dispatch(clearAllBusinesses()),
    showBusinessModal: (business) => dispatch(showBusinessModal(business)),
    fetchBusinesses: (page: number, pagingFunctions: object) => dispatch(fetchBusinesses(page, pagingFunctions))
});

const AdminBusinessesContainer = withRouter(connect(mapStateToProps,mapDispatchToProps)(AdminBusinesses));
export default AdminBusinessesContainer;
