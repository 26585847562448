import axios from "axios";

export const signup = (userData: any) => {
  return axios.post('/api/users', userData, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  });
};


export const login = (userData: any) => {
  return axios.post('/api/session', userData, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  });
};

export const signinWithGoogle = (jwt: any) => {
  return axios.post('/api/auth/google/callback', jwt, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  });
}

export const logout = () => {
  return axios.delete('/api/session');
}