import React from "react";
import Footer from "../footer/footer";
import NavBarContainer from "../navbar/navbar_container";
import { VetCard } from "./vet-card/vet-card.models";
import VetCardComponent from "./vet-card/vet-card";
import { fetchServices } from "../../util/service-api-util";
import queryString from 'query-string';
import { GoogleMap, LoadScript, Autocomplete } from '@react-google-maps/api';

let autocomplete: any;

class LaunchPage extends React.Component {

    child2Ref: React.RefObject<HTMLDivElement>;
    locationTimeout: any;

    // const careTypes = ['General Practive', 'Surgical Care', 'Specialized Care', 
    //     'Holistic Care', 'Physical Therapy', 'Urgent Care', 'Emergency Care', 'End-of-Life Care'];
    displayCareTypes: any[] = [
        {
            id: 1,
            name: 'General Practice'
        },
        {
            id: 2,
            name: 'Surgical Care'
        },
        {
            id: 3,
            name: 'Specialized Care'
        },
        {
            id: 4,
            name: 'Holistic Care'
        },
        {
            id: 5,
            name: 'Physical Therapy'
        },
        {
            id: 6,
            name: 'Urgent Care'
        },
        {
            id: 7,
            name: 'Emergency Care'
        },
        {
            id: 8,
            name: 'End-of-Life Care'
        },
    ];
    // autocomplete: any;

    constructor(props:any){
        super(props);
    
        const date = new Date();
        const dateString = this.getDate(date);
        this.child2Ref = React.createRef();
        this.state = {
            location: "",
            userLocation: "",
            date: dateString,
            lat: null,
            lng: null,
            careType: "",
            vetCards: [],
            userCity: "",
            userCityDisplay: "",
            displayCareType: null,
        }

        this.getCurrentLocation = this.getCurrentLocation.bind(this);
        this.getCurrentLocation();
        this.props.fetchServices();

        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.displayCareType = this.displayCareType.bind(this);
        this.nextDisplayType = this.nextDisplayType.bind(this);
    }

    componentDidMount() {
        const lorem: string = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        const vetCards: VetCard[] = [
            {
                id: 1,
                name: "Long Island City Veterinary Center",
                address: "48-18 Vernon Blvd, Long Island City, NY 11101",
                stars: 5,
                careTypes: ["Urgent Care", "Vaccinations"],
                numReviews: 104,
                featuredReview: lorem
            },
            {
                id: 2,
                name: "Vet Clinic #1",
                address: "Address",
                stars: 5,
                careTypes: ["Urgent Care", "Vaccinations", "Neutering"],
                numReviews: 104,
                featuredReview: lorem
            },
            {
                id: 3,
                name: "Vet Clinic #1",
                address: "Address",
                stars: 5,
                careTypes: ["Urgent Care", "Emergency Services", "Vaccinations", "S","S","S","S","S","S","S","S","S","S"],
                numReviews: 104,
                featuredReview: lorem
            },
            {
                id: 4,
                name: "Vet Clinic #1",
                address: "Address",
                stars: 5,
                careTypes: ["Urgent Care, Vaccinations"],
                numReviews: 104,
                featuredReview: lorem
            },
            {
                id: 5,
                name: "Vet Clinic #1",
                address: "Address",
                stars: 5,
                careTypes: ["Urgent Care", "Vaccinations"],
                numReviews: 104,
                featuredReview: lorem
            }
        ]

        const observer = new IntersectionObserver(
            ([entry]) => {
              if (entry.isIntersecting) {
                let index = 0;
                const userCity: string = this.state.userCity ? this.state.userCity : "Queens, NY";
                this.locationTimeout = setInterval(() => {
                    this.setState((prevState) => ({
                      userCityDisplay: userCity.slice(0,index),
                    }));
                    index++;
                    if (index === userCity.length) {
                      clearInterval(this.locationTimeout);
                    }
                }, 80);

                for (let i = 0; i < vetCards.length; i++)
                {
                    setTimeout(() => {
                        let visibleSetCards = this.state.vetCards;
                        visibleSetCards.push(vetCards[i])
                        this.setState({
                            vetCards: visibleSetCards
                        })
                    }, i* 150)
                }

                let line = document.querySelector('.child-2-line');
                line.style.width="200px";
                
                observer.disconnect(); // Disconnect the observer once the code is run
              }
            },
            { threshold: 0.5 } // Adjust the threshold value as per your requirement
          );
      
          if (this.child2Ref.current) {
            observer.observe(this.child2Ref.current); // Observe the child-2 div
          }
    }

    getCurrentLocation() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const lat = position.coords.latitude;
              const lng = position.coords.longitude;
    
              // Set the user's location in the component's state
              this.setState({
                lat,
                lng,
              });
    
              // Retrieve the general location using a reverse geocoding service
              const geocoder = new window.google.maps.Geocoder();
              const latLng = new window.google.maps.LatLng(lat, lng);
              geocoder.geocode({ location: latLng }, (results, status) => {
                if (status === "OK") {
                  if (results[0]) {
                    const location = results[0].formatted_address;
                    const addressComponents = results[0].address_components;
                    let city = '';
                    let state = '';

                    // Iterate over address components to find city and state
                    for (let i = 0; i < addressComponents.length; i++) {
                        const types = addressComponents[i].types;
                        if (types.includes("locality")) {
                        city = addressComponents[i].long_name;
                        }
                        if (types.includes("administrative_area_level_1")) {
                        state = addressComponents[i].long_name;
                        }
                    }

                    const userCity: string = `${city}, ${state}`;
                    console.log(`USER CITY ${userCity}`)
                    this.setState({
                        location: location,
                        userCity: userCity
                    });
                  }
                }
              });
            },
            (error) => {
              // Handle geolocation error
              console.error("Error getting user's location:", error);
            }
          );
        } else {
          console.error("Geolocation is not supported by this browser.");
        }
      }

    getDate(today: Date){
        return today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
    }

    handleSubmit(e: any){
        e.preventDefault();
        const { location, careType, date, lat, lng } = this.state;
        const searchParams = {
            location,
            careType,
            date,
            lat,
            lng
        };
        searchParams.page = 1;

        const queryStringParams = queryString.stringify(searchParams);
        const route = `/vetsearch?${queryStringParams}`;
        console.log(route);
        this.props.history.push(route);
    }

    handleInput(type: any){
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.currentTarget.value;
            debugger;
            if (type === 'location') {
              this.setState({ location: value });
            } else {
              this.setState({ [type]: value });
            }
        };
    }

    onLoad(autoC: any){
        autocomplete = autoC;
    }

    careTypeCard(careType: any){
        return <li>
            <div className="care-type-card"
                onClick={() => this.setState({displayCareType: careType})}
            >
                {careType.name}
            </div>
        </li>
    }

    displayCareType(){
        if (this.state.displayCareType){
            return <div className="care-type-card-focus">
                <header>{this.state.displayCareType.name}</header>
                <i className="fa-solid fa-xmark close"
                    onClick={() => this.setState({displayCareType: null})}
                ></i>
                <div className="content">
                    <i className="fa-solid fa-chevron-left buttons"
                        onClick={() => this.nextDisplayType(-1)}
                    ></i>
                    <div className="content-middle">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </div>
                    <i className="fa-solid fa-chevron-right buttons"
                        onClick={() => this.nextDisplayType(1)}
                    ></i>
                </div>
            </div>
        }
    }

    nextDisplayType(next: number){
        if (!this.state.displayCareType){
            return;
        }
        let index = -1;
        for (let i = 0; i < this.displayCareTypes.length; i++) {
            if (this.displayCareTypes[i].id === this.state.displayCareType.id) {
              index = i;
            }
        }
        index = (index + next + this.displayCareTypes.length) % this.displayCareTypes.length;
        this.setState({displayCareType: this.displayCareTypes[index]});

    }


    render(){

        const careTypes = ['General Practive', 'Surgical Care', 'Specialized Care', 
        'Holistic Care', 'Physical Therapy', 'Urgent Care', 'Emergency Care', 'End-of-Life Care'];

        return (<div className="launch-page-container" id="launch-page-container">
            <NavBarContainer/>
            <div className="child child-1">
                <div>
                    <div className="launch-page-motto">
                        <div>
                            <span>Book Local </span>
                            <i className="fa-solid fa-location-dot"></i>
                        </div>
                        <div>Vet Clinics in Your Area</div>
                    </div>
                    <form onSubmit={this.handleSubmit}>
                        <div className="launch-page-search-options">
                            <select className="launch-page-search-options-select"
                                onChange={this.handleInput("careType")}
                                value={this.state.careType}
                            >
                                <option value="" disabled selected>Type of Care</option>
                                {Object.values(this.props.services).map((careType: any) => (
                                    <option value={careType.name}>
                                        {careType.name}
                                    </option>
                                ))}
                            </select>
                            <div className="vertical-line"></div>
                            <Autocomplete
                                onLoad={this.onLoad}
                                onPlaceChanged={() => {
                                    const place = autocomplete.getPlace();
                                    this.setState({ 
                                        location: place.formatted_address,
                                        lat: place.geometry.location.lat(),
                                        lng: place.geometry.location.lng(),
                                    });
                                }}
                                // onChange={(e) => {debugger;}}
                                className="launch-page-search-options-area"
                            >
                                <input
                                type="text"
                                placeholder="City, State, or Zip Code"
                                // value={this.state.location}
                                // onChange={this.handleInput("location")}
                                />
                            </Autocomplete>
                            <div className="vertical-line"></div>
                            <input 
                                className="launch-page-search-options-date" 
                                type="date" 
                                onChange={this.handleInput("date")}
                                value={this.state.date}
                            />
                            <button type="submit"><i className="fa fa-search"></i></button>
                        </div>
                    </form>
                </div>


            </div>
            <div className="child child-2"  ref={this.child2Ref}>
                <div className="child-2-inside">
                    <span className="child-2-recommended">
                        Recommended Vet Clinics in:
                    </span>
                    <div className="child-2-location">
                        {this.state.userCityDisplay}
                    </div>
                    <div className="child-2-line" id="child-2-line"></div>
                    <div className="vet-cards-container">
                        {this.state.vetCards.map((vetCard: VetCard, index: number) => {
                            return <VetCardComponent 
                                key={index}
                                vetCard={vetCard}
                            />
                        })}
                    </div>
                </div>
            </div>
            <div className="child child-3">
                <div className="child-3-color-title">
                    What type of Care are you looking for?
                </div>
                <ul>
                    {this.displayCareTypes.map(careType => this.careTypeCard(careType))}
                </ul>
                {this.displayCareType()}
                <img src={window.trees_left} alt="trees-left" className="trees-left" />
                <img src={window.trees_right} alt="trees-right" className="trees-right" />
            </div>
            <div className="child child-4">
                <div className="left">
                    <img src={window.magnets} alt="" />
                </div>
                <div className="right">
                    <header>Seeking better ways to reach your pet patients?</header>
                    <ul>
                        <li>
                            <span className="dot">•</span>
                            Reach new patients in your area
                        </li>
                        <li>
                            <span className="dot">•</span>
                            Showcase your clinic and all it has to offer
                        </li>
                        <li>
                            <span className="dot">•</span>
                            Seamless booking directly through April's Journey
                        </li>
                        <li>
                            <span className="dot">•</span>
                            Boost your online reputation with verified reviews.
                        </li>
                    </ul>
                    <button
                        onClick={() => this.props.history.push("/claim-your-clinic")}
                    >
                        Claim or List your Clinic Here
                    </button>
                </div>


            </div>

            <Footer/>
        </div>)
    }
}

export default LaunchPage
// const LaunchPageGoogle = GoogleApiWrapper({
//     apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
// })(LaunchPage);
// export default LaunchPageGoogle;
