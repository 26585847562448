import React, { useState, useEffect }  from "react";
import { fetchUserBookmarks, toggleUserBookmark } from "../../../util/bookmark-api-util";

const UserProfileBookmarks = (props: any) => {
    const [bookmarks, setBookmarks] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userBookmarks = await fetchUserBookmarks(props.user.id);
                console.log(userBookmarks)
                setBookmarks(userBookmarks.data);
            } catch (error) {
                console.error("Error fetching user bookmarks:", error);
            }
        };

        fetchData();
    }, []);

    const handleToggleClick = (userId, businessId) => {
        let ids = {
            user_id: userId,
            business_id: businessId
        }

        const message = "Are you sure you want to remove this bookmark?";
        const onConfirm = () => {
            toggleUserBookmark({bookmark: ids}).then(res => {
                const removedBookmarks = bookmarks.filter(business => business.id !== businessId);
                setBookmarks(removedBookmarks);
            })
        }
        
        props.showConfirmationForm(message, onConfirm)
    }

    return <div className="user-profile-container-right-bookmarks">
        <header>Bookmarks</header>
        {bookmarks.map( bookmark => {
            return <div className="user-profile-container-right-reviews-card" key={bookmark.id}>
                <div  className="user-profile-container-right-reviews-card-image">
                    <img src={bookmark.image_url} alt="" />
                </div>
                <div className="right">
                    <header>
                        <div>
                            {bookmark.name}
                        </div>
                        <div>
                            <i className="fa-solid fa-bookmark"
                                onClick={() => handleToggleClick(props.user.id, bookmark.id)}
                            />
                            <i className="fa-solid fa-square-arrow-up-right"
                                    onClick={() => props.goToProfile(bookmark.id)}
                                    title="View Business Page"
                            />
                        </div>
                    </header>
                </div>
            </div>
        })}
    </div>
};

export default UserProfileBookmarks;