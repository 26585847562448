import axios from 'axios';

export const fetchAnimalTypes = () => {
    return axios.get(`/api/animals`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
}

export const updateAnimalTypes = (animal: any) => {
    return axios.patch(`/api/animals/${animal.animal.id}`, animal, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
}