import { RECEIVE_ANIMALS, RECEIVE_ANIMAL } from "../actions/animal-actions";

export default (state = {}, action: any)=>{
    Object.freeze(state);
    let nextState: any = Object.assign({}, state)

    switch(action.type){
        case RECEIVE_ANIMAL:
            nextState[action.animal.id] = action.animal;
            return nextState;
        case RECEIVE_ANIMALS:
            action.animals.forEach((animal: any) => {
                nextState[animal.id] = animal;
            })
            return nextState;
        default:
            return state;
    }
}
