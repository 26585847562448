import { RECEIVE_SESSION_ERRORS, CLEAR_SESSION_ERRORS } from "../actions/session_actions";

export default (state = {}, action: any) => {
    Object.freeze(state);
    switch(action.type){
        case RECEIVE_SESSION_ERRORS:
            return Object.assign({},action.errors.response.data);
        case CLEAR_SESSION_ERRORS:
            return {};
        default:
            return state;
    }

}