import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BusinessTypeFormWithToast from "./business-type-form";
import { updateBusinessTypes } from "../../actions/business-type-actions";

const mapStateToProps = (state: any, ownProps: any) => {
    return {

    };
}

const mapDispatchToProps = (dispatch: any) => ({
    updateBusinessTypes: (businessType: any, showToast: Function) => dispatch(updateBusinessTypes(businessType, showToast))
});

const BusinessTypeFormContainer = withRouter(connect(mapStateToProps,mapDispatchToProps)(BusinessTypeFormWithToast));
export default BusinessTypeFormContainer;