import { logoutUser } from "../../actions/session_actions";
import { connect } from "react-redux";
import NavbarWithToast from './navbar';
import { withRouter } from "react-router-dom";

const mapStateToProps = (state: any, ownProps: any) => {
    return {
        loggedIn: Boolean(state.session.id),
        isAdmin: state.entities.users[state.session.id]?.is_admin,
        currentUser: state.entities.users[state.session.id]
    }   
}

const mapDispatchToProps = (dispatch: any) => ({
    logout: (showToast: Function) => dispatch(logoutUser(showToast))
});

const NavBarContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(NavbarWithToast));
export default NavBarContainer;