import { RECEIVE_CLINICS } from "../actions/vet_actions";

export default (state = {}, action: any)=>{
    Object.freeze(state);
    let nextState = Object.assign({}, state)

    switch(action.type){
        case RECEIVE_CLINICS:
            action.clinics.forEach((clinic: any) => {
                nextState[clinic.id] = clinic;
            })
            return nextState;
        default:
            return state;
    }
}