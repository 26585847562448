import React from 'react';
import { ToastConsumer, ToastProvider } from '../../shared/toast.service';
import ToastService from '../../shared/toast.service';

class AnimalForm extends React.Component{

    constructor(props: any){
        super(props);
        this.state = {
            title: this.props.animal ? "Edit Animal" : "Create Animal",
            animal_name: this.props.animal ? this.props.animal.animal_name : "",
            icon: this.props.animal ? this.props.animal.icon : "",
            formType: this.props.animal ? "Update Animal" : "Create Animal"
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInput(type: any){
        console.log(type);
        return (e) => {
            this.setState({ [type]: e.currentTarget.value})
        }
    }

    handleSubmit(e: any){
        e.preventDefault();
        if (this.props.animal){
            debugger;
            let animal: any = {animal: {}};
            animal.animal.id = this.props.animal.id
            animal.animal.animal_name = this.state.animal_name;
            animal.animal.icon = this.state.icon;

            // Conditionally determine whether or not update or create action is used based on if props.id exists
            this.props.updateAnimalTypes(animal, this.props.showToast);
        }
        // this.props.processSessionForm(user);
    }

    render() {
        return <div className='modal-container-animal-form'>
            <header>{this.state.title}</header>
            <form onSubmit={this.handleSubmit}>
                <label>
                    <div>Name</div>
                    <input type="text" 
                        value={this.state.animal_name}
                        onChange={this.handleInput("animal_name")}
                    />
                </label>
                <label>
                    <div>Icon</div>
                    <input type="text" 
                        value={this.state.icon} 
                        onChange={this.handleInput("icon")}
                    />
                </label>
                <div className='icon-container'>
                    <div className='modal-container-animal-form-icon'>
                        <i className={this.state.icon}></i>
                        <label>{this.state.animal_name}</label>
                    </div>
                </div>
                <div className='buttons'>
                    <input 
                        type="submit"
                        value={this.state.formType}
                        className="submit"
                    />
                    {this.props.animal ? <button>
                        Delete Animal
                    </button> : ""}
                    
                </div>
            </form>
        </div>
    }
}

const AnimalFormWithToast = (props: any) => (
        <ToastConsumer>
        {(showToast) => <AnimalForm showToast={showToast} {...props} />}
        </ToastConsumer>
);

export default AnimalFormWithToast;