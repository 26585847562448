import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import AnimalForm from "./animal-form";
import AnimalFormWithToast from "./animal-form";
import { updateAnimalTypes } from "../../actions/animal-actions";

const mapStateToProps = (state: any, ownProps: any) => {
    return {

    };
}

const mapDispatchToProps = (dispatch: any) => ({
    updateAnimalTypes: (animal: any, showToast: Function) => dispatch(updateAnimalTypes(animal,showToast)),
    // TODO: Add in Create Animal Types action
});

const AnimalFormContainer = withRouter(connect(mapStateToProps,mapDispatchToProps)(AnimalFormWithToast));
export default AnimalFormContainer;