import axios from "axios";

export const fetchBusinesses = (page: number) => {
    return axios.get(`/api/businesses?page=${page}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
}

export const fetchBusiness = (id: number) => {
    return axios.get(`/api/businesses/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
}

export const fetchBusinessesFromFilter = (filter: any) => {
    return axios.post(`/api/businesses/fetchBusinessesFromFilter`, filter, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
}

export const fetchTotalPageCountFromFilter = (filter: any) => {
    return axios.post(`/api/businesses/fetchTotalPageCountFromFilter`, filter, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
}

export const createBusiness = (business: any) => {
    return axios.post(`/api/businesses`, business, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
}

export const updateBusiness = (business: any) => {
    return axios.patch(`/api/businesses/${business.business.id}`, business, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
}