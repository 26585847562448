import React from "react";
import { Link } from "react-router-dom";

class AdminOverview extends React.Component {

    constructor(props: any){
        super(props);
    }

    componentDidMount(): void {
        this.props.fetchAnimalTypes();
        this.props.fetchBusinessTypes();
        this.props.fetchServices();
    }

    animalIcon(animal: any){
        return <div className="admin-overview-container-animals-info">
            <div className="admin-overview-container-animals-info-edit"
                onClick={() => this.props.showAdminAnimalsForm(animal)}
            >
                <i className="fa-solid fa-pen-to-square"></i>
            </div>
            <i className={animal.icon}></i>
            <label>{animal.animal_name}</label>
        </div>
    }

    businessTypeIcon(businessType: any){
        return <div className="admin-overview-container-business-types-info">
            <div className="admin-overview-container-business-types-info-edit"
                onClick={() => this.props.showAdminBusinessTypesForm(businessType)}
            >
                <i className="fa-solid fa-pen-to-square"></i>
            </div>
            <i className={businessType.icon}></i>
            <label>{businessType.business_type}</label>
        </div>
    }

    serviceIcon(service: any){
        return <div className="admin-overview-container-services-info">
            <div className="admin-overview-container-services-info-edit"
                onClick={() => this.props.showAdminServicesForm(service)}
            >
                <i className="fa-solid fa-pen-to-square"></i>
            </div>
            <label>{service.name}</label>
        </div>
    }

    render() {
        return (<div className="admin-overview-container">
            <div
                className="admin-overview-container-routing-buttons"
            >
                <button  
                    onClick={() => {
                        this.props.clearAllBusinesses();
                        this.props.history.push('/admin-overview/businesses');
                    }}
                    >
                        <i className="fa-solid fa-building"></i>
                        <div>See Businesses</div>
                </button>
                <button  
                    onClick={() => {
                        this.props.clearAllBusinesses();
                        this.props.history.push('/admin-overview/users');
                    }}
                    >
                        <i className="fa-solid fa-user"></i>
                        <div>See Users</div>
                </button>
            </div>
            <h2 htmlFor="">Animals:</h2>
            <div className="admin-overview-container-animals">
                {Object.values(this.props.animals).map((animal: any) => this.animalIcon(animal))}
                <div className="admin-overview-container-animals-info add"
                    onClick={() => this.props.showAdminAnimalsForm()}
                >
                    <i className="fa-solid fa-plus"></i>
                    <label>Add Animal</label>
                </div>
            </div>
            <h2 htmlFor="">Business Types:</h2>
            <div className="admin-overview-container-business-types">
                {Object.values(this.props.businessTypes).map((businessType: any) => this.businessTypeIcon(businessType))}
                <div className="admin-overview-container-business-types-info add"
                    onClick={() => this.props.showAdminBusinessTypesForm()}
                >
                    <i className="fa-solid fa-plus"></i>
                    <label>Add Business Type</label>
                </div>
            </div>
            <h2>Services:</h2>
            <div className="admin-overview-container-services">
                {Object.values(this.props.services).map((service: any) => this.serviceIcon(service))}
                <div className="admin-overview-container-services-info add"
                    onClick={() => this.props.showAdminServicesForm()}
                >
                    <i className="fa-solid fa-plus"></i>
                    <label>Add Service Type</label>
                </div>
            </div>
        </div>)
    }
}

export default AdminOverview;