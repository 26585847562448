import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BusinessFormWithToast from "./business-form";
import { fetchBusinessTypes } from "../../actions/business-type-actions";
import { fetchServices } from "../../actions/service-actions";
import { createBusiness, updateBusiness } from "../../actions/business-actions";
import { fetchAnimalTypes } from "../../actions/animal-actions";


const mapStateToProps = (state: any, ownProps: any) => ({
    services: state.entities.services,
    businessTypes: state.entities.businessRoot.businessTypes,
    animals: state.entities.animals
})

const mapDispatchToProps = (dispatch: any) => ({
    fetchBusinessTypes: () => dispatch(fetchBusinessTypes()),
    fetchServices: () => dispatch(fetchServices()),
    createBusiness: (business: any, showToast: Function) => dispatch(createBusiness(business, showToast)),
    updateBusiness: (business: any, showToast: Function) => dispatch(updateBusiness(business, showToast)),
    fetchAnimalTypes: () => dispatch(fetchAnimalTypes())
});

const BusinessFormContainer = withRouter(connect(mapStateToProps,mapDispatchToProps)(BusinessFormWithToast));
export default BusinessFormContainer;