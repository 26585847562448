import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import VetSearchComponent from "./vet-search";
import { fetchVetsFromFilter } from "../../../actions/vet_actions";
import { fetchServices } from "../../../actions/service-actions";
import { fetchBusinessesFromFilter } from "../../../actions/business-actions";
import { fetchAnimalTypes } from "../../../actions/animal-actions";

const mapStateToProps = (state: any, ownProps: any) => ({
    vets: state.entities.vets,
    services: state.entities.services,
    searchedBusinesses: state.entities.businessRoot.searchedBusinesses,
    animals: state.entities.animals
})

const mapDispatchToProps = (dispatch: any) => ({
    fetchVets: (filter: any) => dispatch(fetchVetsFromFilter(filter)),
    fetchBusinessesFromFilter: (filter: any) => dispatch(fetchBusinessesFromFilter(filter)),
    fetchServices: () => dispatch(fetchServices()),
    fetchAnimalTypes: () => dispatch(fetchAnimalTypes())
});

const VetSearchContainer = withRouter(connect(mapStateToProps,mapDispatchToProps)(VetSearchComponent));
export default VetSearchContainer;