import React from "react";
import { ToastConsumer, ToastProvider } from '../../shared/toast.service';
import ToastService from '../../shared/toast.service';

class BusinessForm extends React.Component {

    constructor(props:any){
        super(props);
        
        this.state = {
            title: this.props.business ? "Edit Business" : "Create Business",
            formType: this.props.business ? "Update Business" : "Create Business",
            id: this.props.business?.id,
            services: this.props.business ? this.props.business?.services.map(service => service.id) : [],
            name: this.props.business?.name ? this.props.business?.name : "",
            businessType: this.props.business?.business_type.id ? this.props.business?.business_type.id : null,
            address: this.props.business?.address ? this.props.business?.address : "",
            website: this.props.business?.website ? this.props.business?.website : "",
            phone: this.props.business?.phone ? this.props.business?.phone: "",
            email: this.props.business?.email ? this.props.business?.email : "",
            place_id: this.props.business?.place_id ? this.props.business?.place_id : "",
            description: this.props.business?.description ? this.props.business?.description : "",
            lat: this.props.business?.lat ? this.props.business?.lat : "",
            lng: this.props.business?.lng ? this.props.business?.lng : "",
            image_url: this.props.business?.image_url ? this.props.business?.image_url : "",
            animal_ids: this.props.business?.animal_ids ? this.props.business?.animal_ids : []
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleServiceCheckbox = this.handleServiceCheckbox.bind(this);
        this.handleAnimalCheckbox = this.handleAnimalCheckbox.bind(this);
    }

    componentDidMount(): void {
        this.props.fetchAnimalTypes();
        this.props.fetchBusinessTypes();
        this.props.fetchServices();
    }


    handleInput(type: any){
        return (e) => {
            this.setState({ [type]: e.currentTarget.value})
        }
    }

    handleSubmit(e: Event){
        e.preventDefault();
        let business = Object.assign({},this.state);
        business.lat = parseFloat(business.lat);
        business.lng = parseFloat(business.lng);
        debugger;

        if (this.props.business){
            this.props.updateBusiness({business: business}, this.props.showToast);
        } else {    
            this.props.createBusiness({business: business}, this.props.showToast);
        }
    } 

    handleServiceCheckbox(e){
        let services = [...this.state.services];
        services = services.filter( services => services !== parseInt(e.target.value));
        if (services.length === this.state.services.length){
            services.push(parseInt(e.target.value));
        } 
        this.setState({services: services});
    }

    handleAnimalCheckbox(e){
        let animal_ids = [...this.state.animal_ids];
        animal_ids = animal_ids.filter( animal_id => animal_id !== parseInt(e.target.value));
        if (animal_ids.length === this.state.animal_ids.length){
            animal_ids.push(parseInt(e.target.value));
        }
        this.setState({animal_ids: animal_ids});
    }

    alphabetServices(services: any){
        const sortedServices = Object.values(services).sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
          
            if (nameA < nameB) {
              return -1; // a should come before b
            }
            if (nameA > nameB) {
              return 1; // a should come after b
            }
            return 0; // names are equal
        });
        return sortedServices
    }

    render(){
        return <div className="modal-container-business-form">
            <header>{this.state.title}</header>
            <form
                onSubmit={this.handleSubmit}
            >
                <label>
                    <div>Name</div>
                    <input type="text" 
                        value={this.state.name}
                        onChange={this.handleInput("name")}
                    />
                </label>
                <label>
                    <div>Business Type</div>
                    <select className="vet-search-container-left-search-options-select"
                        onChange={this.handleInput("businessType")}
                        value={this.state.careType}
                    >
                        {this.state.businessType ? "" : <option value="" disabled selected>Type of Care</option> }
                        {Object.values(this.props.businessTypes).map((businessType: any) => (
                            <option value={businessType.id}>
                                {businessType.business_type}
                            </option>
                        ))}
                    </select>
                </label>
                <div className="label-row">
                    <div>Services</div>
                    <ul>{this.alphabetServices(this.props.services).map((service:any) => {
                        return <label className="no-margin-label">
                            <li
                                onClick={(e) => e.stopPropagation()}
                            ><input 
                                    type="checkbox"
                                    value={service.id}
                                    onClick={this.handleServiceCheckbox}
                                    checked={this.state.services.includes(service.id)}
                                />
                                <div className="service-name"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    {service.name}
                                </div>
                                </li>

                        </label>
                    })}</ul>
                </div>
                <div className="label-row">
                    <div>Animals</div>
                    <ul>{Object.values(this.props.animals).map((animal:any) => {
                        return <label className="no-margin-label">
                            <li
                                onClick={(e) => e.stopPropagation()}
                            ><input 
                                    type="checkbox"
                                    value={animal.id}
                                    onClick={this.handleAnimalCheckbox}
                                    checked={this.state.animal_ids.includes(animal.id)}
                                />
                                <div className="service-name"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    {animal.animal_name}
                                </div>
                                </li>

                        </label>
                    })}</ul>
                </div>
                <label>
                    <div>Address</div>
                    <input type="text" 
                        value={this.state.address}
                        onChange={this.handleInput("address")}
                    />
                </label>
                <label>
                    <div>Website</div>
                    <input type="text" 
                        value={this.state.website}
                        onChange={this.handleInput("website")}
                    />
                </label>
                <label>
                    <div>Phone</div>
                    <input type="text" 
                        value={this.state.phone}
                        onChange={this.handleInput("phone")}
                    />
                </label>
                <label>
                    <div>Email</div>
                    <input type="text" 
                        value={this.state.email}
                        onChange={this.handleInput("email")}
                    />
                </label>
                <label>
                    <div>Image URL</div>
                    <input type="text" 
                        value={this.state.image_url}
                        onChange={this.handleInput("image_url")}
                    />
                </label>
                <label>
                    <div>Place Id</div>
                    <input type="text" 
                        value={this.state.place_id}
                        onChange={this.handleInput("place_id")}
                    />
                </label>
                <label>
                    <div>Latitude</div>
                    <input type="text" 
                        value={this.state.lat}
                        onChange={this.handleInput("lat")}
                    />
                </label>
                <label>
                    <div>Longitude</div>
                    <input type="text" 
                        value={this.state.lng}
                        onChange={this.handleInput("lng")}
                    />
                </label>
                <label>
                    <div>Description</div>
                    <textarea
                        value={this.state.description}
                        onChange={this.handleInput("description")}
                    />
                </label>

                <input type="submit" 
                    value={this.state.formType}
                    className="submit"
                />
                {this.props.business ? <button>
                    Delete Business
                </button> : ""}
            </form>
        </div>
    }
}


const BusinessFormWithToast = (props: any) => (
    <ToastConsumer>
    {(showToast) => <BusinessForm showToast={showToast} {...props} />}
    </ToastConsumer>
);

export default BusinessFormWithToast;