import * as BusinessTypeApiUtil from '../util/business-types-api-util'
import { ToastType } from "../shared/toast.service";

export const RECEIVE_BUSINESS_TYPES = "RECEIVE_BUSINESS_TYPES";
export const RECEIVE_BUSINESS_TYPE = "RECEIVE_BUSINESS_TYPE";

export const receiveBusinessType = (businessType: any) => ({
    type: RECEIVE_BUSINESS_TYPE,
    businessType
})

export const receiveBusinessTypes = (businessTypes: any) => ({
    type: RECEIVE_BUSINESS_TYPES,
    businessTypes
})

export const fetchBusinessTypes = () => (dispatch: any) => {
    BusinessTypeApiUtil.fetchBusinessTypes().then(businessTypes => {
        dispatch(receiveBusinessTypes(businessTypes.data));
    })
}

export const updateBusinessTypes = (businessType: any, showToast: Function) => (dispatch: any) => {
    BusinessTypeApiUtil.updateBusinessTypes(businessType).then(businessType => {
        dispatch(receiveBusinessType(businessType.data));
        showToast(ToastType.Success,"Business Type successfully updated.");
    }, err => {
        showToast(ToastType.Error,"Business Type not updated.");
    })
}