
import { combineReducers } from "redux";

import usersReducer from "./users_reducer";
import vetsReducer from "./vets-reducer";
import animalsReducer from "./animals-reducer";
import servicesReducer from "./services-reducer";
import reviewsReducer from "./reviews-reducers";
import businessRootReducer from "./business/business-root-reducer";

const entitiesReducer = combineReducers({
  businessRoot: businessRootReducer,
  users: usersReducer,
  vets: vetsReducer,
  animals: animalsReducer,
  services: servicesReducer,
  reviews: reviewsReducer
});

export default entitiesReducer;